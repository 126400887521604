import React from "react";
import ShareIcon from "@material-ui/icons/Share";
import SecurityIcon from "@material-ui/icons/Security";
import StorageIcon from "@material-ui/icons/Storage";
import "../../styles/Legal.css";

function PrivacyPolicySummary() {
  return (
    <div className="summary">
      <div className="summaryBlocks">
        <div className="summaryHeader">
          <div className="summaryIcon">
            <SecurityIcon style={{ color: "#CCCCCC", width: 24 }} />
          </div>
          Data Security
        </div>
        <p className="summaryPoints">
          We strive to implement the latest security protocols to keep your data
          safe.
          <br/>
          <br/>
          Your data is encrypted during transfer from devices and servers.
        </p>
      </div>

      <div className="summaryBlocks">
        <div className="summaryHeader">
          <div className="summaryIcon">
            <ShareIcon style={{ color: "#CCCCCC", width: 24 }} />
          </div>
          Data Sharing
        </div>
        <p className="summaryPoints">
          We do not sell data to third parties.
          <br />
          <br />
          Your data will not be used for targeted ads without your explicit
          permission.
        </p>
      </div>

      <div className="summaryBlocks">
        <div className="summaryHeader">
          <div className="summaryIcon">
            <StorageIcon style={{ color: "#CCCCCC", width: 24 }} />
          </div>
          Data Control
        </div>
        <p className="summaryPoints">
          We'll purge your data from our systems upon request.
          <br />
          <br />
          You can also request an archive of your data at any time.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicySummary;
