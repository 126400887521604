import React from "react";

export function TermsOfService(){
    return(
        <div className="legal-body">
            PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT CAREFULLY. BY ACCESSING OR USING OUR SITES AND
            OUR SERVICES, YOU HEREBY AGREE TO BE BOUND BY THE TERMS AND ALL TERMS INCORPORATED HEREIN BY
            REFERENCE. IT IS THE RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE
            TERMS AND CONDITIONS BEFORE PROCEEDING TO USE THIS SITE. IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THE
            TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITES OR OUR SERVICES. THIS TERMS OF
            SERVICE AGREEMENT IS EFFECTIVE AS OF 06/01/2018.
            <div className='body-header'>ACCEPTANCE OF TERMS</div>
            The following Terms of Service Agreement (the "TOS") is a legally binding agreement that shall
            govern the relationship with our users and others which may interact or interface with Liferoll
            Inc., also known as Liferoll, located at 548 Market St PMB 46452 San Francisco, California 94104 and our
            subsidiaries and affiliates, in association with the use of the Liferoll platform, which includes
            http://www.liferoll.co, (the "Site"), the Liferoll iOS and Android App (the "App") and its Services, which shall be defined below (together are referred to as the "Platform").
            <div className='body-header'>DESCRIPTION OF PLATFORM SERVICES OFFERED</div>
            The Platform is a social networking and file storage service which has the following description: Liferoll provides
            internet and app-based services for social networking and utility applications for creating, organizing, searching
            and sharing media around memories. Any and all visitors to our site, despite whether
            they are registered or not, shall be deemed as "users" of the herein contained Services provided for
            the purpose of this TOS. Once an individual register's for our Services, through the process of
            creating an account, the user shall then be considered a "member."
            The user and/or member acknowledges and agrees that the Services provided and made available through
            our website and applications, which may include some mobile applications and that those applications
            may be made available on various social media networking sites and numerous other platforms and
            downloadable programs, are the sole property of Liferoll Inc. At its discretion, Liferoll Inc may
            offer additional website and application Services and/or products, or update, modify or revise any current content
            and Services, and this Agreement shall apply to any and all additional Services and/or products and
            any and all updated, modified or revised Services unless otherwise stipulated. Liferoll Inc does
            hereby reserve the right to cancel and cease offering any of the aforementioned Services and/or
            products. You, as the end user and/or member, acknowledge, accept and agree that Liferoll Inc shall
            not be held liable for any such updates, modifications, revisions, suspensions or discontinuance of
            any of our Services and/or products. Your continued use of the Services provided, after such posting
            of any updates, changes, and/or modifications shall constitute your acceptance of such updates,
            changes and/or modifications, and as such, frequent review of this Agreement and any and all
            applicable terms and policies should be made by you to ensure you are aware of all terms and
            policies currently in effect. Should you not agree to the updated, revised or modified terms, you
            must stop using the provided Services forthwith.
            Furthermore, the user and/or member understands, acknowledges and agrees that the Services offered
            shall be provided "AS IS" and as such Liferoll Inc shall not assume any responsibility or obligation
            for the timeliness, missed delivery, deletion and/or any failure to store user content,
            communication or personalization settings.
            <div className='body-header'>REGISTRATION</div>
            To register and become a "member" of the Platform, you must be at least 18 years of age to enter into
            and form a legally binding contract. In addition, you must be in good standing and not an individual
            that has been previously barred from receiving Liferoll's Services under the laws and statutes of
            the United States or other applicable jurisdiction.
            When you register, Liferoll may collect information such as your name, e-mail address, birth date,
            gender, mailing address, and personal interests. You can edit your account
            information at any time. Once you register with Liferoll and sign in to our Services, you are no
            longer anonymous to us.
            Furthermore, the registering party hereby acknowledges, understands and agrees to:
                <br/>  <br/>
            a) furnish factual, correct, current and complete information with regards to yourself as may be
            requested by the data registration process, and             <br/>
            b) maintain and promptly update your registration and profile information in an effort to maintain
            accuracy and completeness at all times.            <br/>  <br/>
            If anyone knowingly provides any information of a false, untrue, inaccurate or incomplete nature,
            Liferoll Inc will have sufficient grounds and rights to suspend or terminate the member in violation
            of this aspect of the Agreement, and as such refuse any and all current or future use of Liferoll
            Inc Services, or any portion thereof.
            It is Liferoll Inc's priority to ensure the safety and privacy of all its visitors, users and
            members, especially that of children. Therefore, it is for this reason that the parents of any child
            under the age of 13 that permit their child or children access to the Liferoll platform
            Services must create a "family" account, which will certify that the individual creating the
            "family" account is of 18 years of age and as such, the parent or legal guardian of any child or
            children registered under the "family" account. As the creator of the "family" account, s/he is
            thereby granting permission for his/her child or children to access the various Services provided,
            including, but not limited to, message boards, email, and/or instant messaging. It is the parent's
            and/or legal guardian's responsibility to determine whether any of the Services and/or content
            provided are age-appropriate for his/her child.
            <div className='body-header'>PRIVACY POLICY</div>
            Every member's registration data and various other personal information are strictly protected by
            the Liferoll Inc Online Privacy Policy (see the full Privacy Policy at
            http://www.liferoll.co/privacy
            As a member, you herein consent to the collection and use of the information provided, including the
            transfer of information within the United States and/orother countries for storage, processing or
            use by Liferoll Inc and/or our subsidiaries and affiliates.
            <div className='body-header'>MEMBER ACCOUNT, USERNAME, PASSWORD AND SECURITY</div>
            When you set up an account, you are the sole authorized user of your account. You shall be
            responsible for maintaining the secrecy and confidentiality of your password and for all activities
            that transpire on or within your account. It is your responsibility for any act or omission of any
            user(s) that access your account information that, if undertaken by you, would be deemed a violation
            of the TOS. It shall be your responsibility to notify Liferoll Inc immediately if you notice any
            unauthorized access or use of your account or password or any other breach of security. Liferoll Inc
            shall not be held liable for any loss and/or damage arising from any failure to comply with this
            term and/or condition of the TOS.
            <div className='body-header'>CONDUCT</div>
            As a user or member of the Platform, you herein acknowledge, understand and agree that all information,
            text, software, data, photographs, music, video, messages, tags or any other content, whether it is
            publicly or privately posted and/or transmitted, is the expressed sole responsibility of the
            individual from whom the content originated. In short, this means that you are solely responsible
            for any and all content posted, uploaded, emailed, transmitted or otherwise made available by way of
            the Liferoll Services, and as such, we do not guarantee the accuracy, integrity or quality of such
            content. It is expressly understood that by use of our Services, you may be exposed to content
            including, but not limited to, any errors or omissions in any content posted, and/or any loss or
            damage of any kind incurred as a result of the use of any content posted, emailed, transmitted or
            otherwise made available by Liferoll. Furthermore, you herein agree not to make use of Liferoll Inc's
            Services for the purpose of:  <br/> <br/>
            a) uploading, posting, emailing, transmitting, or otherwise making available any content that shall
            be deemed unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene,
            libelous, or invasive of another's privacy or which is hateful, and/or racially, ethnically, or
            otherwise objectionable;  <br/> <br/>
            b) causing harm to minors in any manner whatsoever;<br/> <br/>
            c) impersonating any individual or entity, including, but not limited to, any Liferoll officials,
            forum leaders, guides or hosts or falsely stating or otherwise misrepresenting any affiliation with
            an individual or entity;<br/> <br/>
            d) forging captions, headings or titles or otherwise offering any content that you personally have
            no right to pursuant to any law nor having any contractual or fiduciary relationship with;<br/> <br/>
            e) uploading, posting, emailing, transmitting or otherwise offering any such content that may
            infringe upon any patent, copyright, trademark, or any other proprietary or intellectual rights of
            any other party;<br/> <br/>
            f) uploading, posting, emailing, transmitting or otherwise offering any content that you do not
            personally have any right to offer pursuant to any law or in accordance with any contractual or
            fiduciary relationship;<br/> <br/>
            g) uploading, posting, emailing, transmitting, or otherwise offering any unsolicited or unauthorized
            advertising, promotional flyers, "junk mail," "spam," or any other form of solicitation, except in
            any such areas that may have been designated for such purpose;<br/> <br/>
            h) uploading, posting, emailing, transmitting, or otherwise offering any source that may contain a
            software virus or other computer code, any files and/or programs which have been designed to
            interfere, destroy and/or limit the operation of any computer software, hardware, or
            telecommunication equipment;<br/> <br/>
            i) disrupting the normal flow of communication, or otherwise acting in any manner that would
            negatively affect other users' ability to participate in any real time interactions;<br/> <br/>
            j) interfering with or disrupting any Liferoll Inc Services, servers and/or networks that may be
            connected or related to our website and application, including, but not limited to, the use of any device software
            and/or routine to bypass the robot exclusion headers;<br/> <br/>
            k) intentionally or unintentionally violating any local, state, federal, national or international
            law, including, but not limited to, rules, guidelines, and/or regulations decreed by the U.S.
            Securities and Exchange Commission, in addition to any rules of any nation or other securities
            exchange, that would include without limitation, the New York Stock Exchange, the American Stock
            Exchange, or the NASDAQ, and any regulations having the force of law;<br/> <br/>
            l) providing informational support or resources, concealing and/or disguising the character,
            location, and or source to any organization delegated by the United States government as a "foreign
            terrorist organization" in accordance to Section 219 of the Immigration Nationality Act;<br/> <br/>
            m) "stalking" or with the intent to otherwise harass another individual; and/or<br/> <br/>
            n) collecting or storing of any personal data relating to any other member or user in connection
            with the prohibited conduct and/or activities which have been set forth in the aforementioned
            paragraphs.<br/> <br/>
            Liferoll Inc herein reserves the right to pre-screen, refuse and/or delete any content currently
            available through our Services. In addition, we reserve the right to remove and/or delete any such
            content that would violate the TOS or which would otherwise be considered offensive to other
            visitors, users and/or members.
            Liferoll Inc herein reserves the right to access, preserve and/or disclose member account
            information and/or content if it is requested to do so by law or in good faith belief that any such
            action is deemed reasonably necessary for:<br/> <br/>
            a) compliance with any legal process;<br/> <br/>
            b) enforcement of the TOS;<br/> <br/>
            c) responding to any claim that therein contained content is in violation of the rights of any third
            party;<br/> <br/>
            d) responding to requests for customer service; or<br/> <br/>
            e) protecting the rights, property or the personal safety ofLiferoll Inc, its visitors, users and
            members, including the general public.<br/> <br/>
            Liferoll Inc herein reserves the right to include the use of security components that may permit
            digital information or material to be protected, and that such use of information and/or material is
            subject to usage guidelines and regulations established by Liferoll Inc or any other content
            providers supplying content services to Liferoll Inc. You are hereby prohibited from making any
            attempt to override or circumvent any of the embedded usage rules in our Services. Furthermore,
            unauthorized reproduction, publication, distribution, or exhibition of any information or materials
            supplied by our Services, despite whether done so in whole or in part, is expressly Prohibited.
            <div className='body-header'>INTERSTATE COMMUNICATION</div>
            Upon registration, you hereby acknowledge that by using http://www.liferoll.co to send electronic
            communications, which would include, but are not limited to, email, searches, instant messages,
            uploading of files, photos and/or videos, you will be causing communications to be sent through our
            computer network. Therefore, through your use, and thus your agreement with this TOS, you are
            acknowledging that the use of this Service shall result in interstate Transmissions.
            <div className='body-header'>CAUTIONS FOR GLOBAL USE AND EXPORT AND IMPORT COMPLIANCE</div>
            Due to the global nature of the internet, through the use of our network you hereby agree to comply
            with all local rules relating to online conduct and that which is considered acceptable Content.
            Uploading, posting and/or transferring of software, technology and other technical data may be
            subject to the export and import laws of the United States and possibly other countries. Through the
            use of our network, you thus agree to comply with all applicable export and import laws, statutes
            and regulations, including, but not limited to, the Export Administration Regulations
            (http://www.access.gpo.gov/bis/ear/ear_data.html), as well as the sanctions control program of The
            United States (http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx).
            Furthermore, you state and pledge that you:<br/> <br/>
            a) are not on the list of prohibited individuals which may be identified on any government export
            exclusion report (http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm ) nor a member of
            any other government which may be part of an export-prohibited country identified in applicable
            export and import laws and regulations;<br/> <br/>
            b) agree not to transfer any software, technology or any other technical data through the use of our
            network Services to any export-prohibited country;<br/> <br/>
            c) agree not to use our platform network Services for any military, nuclear, missile, chemical or
            biological weaponry end uses that would be a violation of the U.S. export laws; and
            d) agree not to post, transfer nor upload any software, technology or any other technical data which
            would be in violation of the U.S. or other applicable export and/or import laws.<br/> <br/>
            CONTENT PLACED OR MADE AVAILABLE FOR COMPANY SERVICES
            Liferoll Inc shall not lay claim to ownership of any content submitted by any visitor, member, or
            user, nor make such content available for inclusion on our platform Services. Therefore, you hereby
            grant and allow for Liferoll Inc the below listed worldwide, royalty-free and non- exclusive
            licenses, as applicable:<br/> <br/>
            a) The content submitted or made available for inclusion on the publicly accessible areas of
            Liferoll Inc's sites, the license provided to permit to use, distribute, reproduce, modify, adapt,
            publicly perform and/or publicly display said Content on our network Services is for the sole
            purpose of providing and promoting the specific area to which this content was placed and/or made
            available for viewing. This license shall be available so long as you are a member of Liferoll Inc's
            sites, and shall terminate at such time when you elect to discontinue your membership.<br/> <br/>
            b) Photos, audio, video and/or graphics submitted or made available for inclusion on the publicly
            accessible areas of Liferoll Inc's sites, the license provided to permit to use, distribute,
            reproduce, modify, adapt, publicly perform and/or publicly display said Content on our network
            Services are for the sole purpose of providing and promoting the specific area in which this content
            was placed and/or made available for viewing. This license shall be available so long as you are a
            member of Liferoll Inc's sites and shall terminate at such time when you elect to discontinue your
            membership.<br/> <br/>
            c) For any other content submitted or made available for inclusion on the publicly accessible areas
            of Liferoll Inc's sites, the continuous, binding and completely sub-licensable license which is
            meant to permit to use, distribute, reproduce, modify, adapt, publish, translate, publicly perform
            and/or publicly display said content, whether in whole or in part, and the incorporation of any such
            Content into other works in any arrangement or medium current used or later developed.
            Those areas which may be deemed "publicly accessible" areas ofLiferoll Inc's sites are those such
            areas of our network properties which are meant to be available to the general public, and which
            would include message boards and groups that are openly available to both users and members.
            However, those areas which are not open to the public, and thus available to members only, would
            include our mail system and instant messaging.
            <div className='body-header'>CONTRIBUTIONS TO COMPANY PLATFORM</div>
            Liferoll Inc provides an area for our users and members to contribute feedback to our platform. When
            you submit ideas, documents, suggestions and/or proposals ("Contributions") to our site, you
            acknowledge and agree that:<br/> <br/>
            a) your contributions do not contain any type of confidential or proprietary information;<br/> <br/>
            b) Liferoll shall not be liable or under any obligation to ensure or maintain confidentiality,
            expressed or implied, related to any Contributions;<br/> <br/>
            c) Liferoll shall be entitled to make use of and/or disclose any such Contributions in any such
            manner as they may see fit;<br/> <br/>
            d) the contributor's Contributions shall automatically become the sole property of Liferoll; and<br/> <br/>
            e) Liferoll is under no obligation to either compensate or provide any form of reimbursement in any
            manner or nature.<br/> <br/>
            INDEMNITY
            All users and/or members herein agree to insure and hold Liferoll Inc, our subsidiaries, affiliates,
            agents, employees, officers, partners and/or licensors blameless or not liable for any claim or
            demand, which may include, but is not limited to, reasonable attorney fees made by any third party
            which may arise from any content a member or user of our site may submit, post, modify, transmit or
            otherwise make available through our Services, the use of Liferoll Services or your connection with
            these Services, your violations of the Terms of Service and/or your violation of any such rights of
            another person.
            <div className='body-header'>COMMERCIAL REUSE OF SERVICES</div>
            The member or user herein agrees not to replicate, duplicate, copy, trade, sell, resell nor exploit
            for any commercial reason any part, use of, or access to Liferoll's sites.
            <div className='body-header'>USE AND STORAGE GENERAL PRACTICES</div>
            You herein acknowledge that Liferoll Inc may set up any such practices and/or limits regarding the
            use of our Services, without limitation of the maximum number of days that any email, message
            posting or any other uploaded content shall be retained by Liferoll Inc, nor the maximum number of
            email messages that may be sent and/or received by any member, the maximum volume or size of any
            email message that may be sent from or may be received by an account on our Service, the maximum
            disk space allowable that shall be allocated on Liferoll Inc.'s servers on the member's behalf,
            and/or the maximum number of times and/or duration that any member may access our Services in a
            given period of time. In addition, you also agree that Liferoll Inc has absolutely no responsibility
            or liability for the removal or failure to maintain storage of any messages and/or other
            communications or content maintained or transmitted by our Services. You also herein acknowledge
            that we reserve the right to delete or remove any account that is no longer active for an extended
            period of time. Furthermore, Liferoll Inc shall reserve the right to modify, alter and/or update
            these general practices and limits at our discretion.
            Any messenger service, which may include any web-based versions, shall allow you and the individuals
            with whom you communicate with the ability to save your conversations in your account located on
            Liferoll Inc's servers. In this manner, you will be able to access and search your message history
            from any computer with internet access. You also acknowledge that others have the option to use and
            save conversations with you in their own personal account on http://www.liferoll.co. It is your
            agreement to this TOS which establishes your consent to allow Liferoll Inc to store any and all
            communications on its servers.
            <div className='body-header'>MODIFICATIONS</div>
            Liferoll Inc shall reserve the right at any time it may deem fit, to modify, alter and or
            discontinue, whether temporarily or permanently, our service, or any part thereof, with or without
            prior notice. In addition, we shall not be held liable to you or to any third party for any such
            alteration, modification, suspension and/or discontinuance of our Services, or any part thereof.
            <div className='body-header'>TERMINATION</div>
            As a member of http://www.liferoll.co, you may cancel or terminate your account, associated email
            address and/or access to our Services by submitting a cancellation or termination request to
            support@liferoll.co.
            As a member, you agree that Liferoll Inc may, without any prior written notice, immediately suspend,
            terminate, discontinue and/or limit your account, any email associated with your account, and access
            to any of our Services. The cause for such termination, discontinuance, suspension and/or limitation
            of access shall include, but is not limited to:<br/> <br/>
            a) any breach or violation of our TOS or any other incorporated agreement, regulation and/or
            guideline;<br/> <br/>
            b) by way of requests from law enforcement or any other governmental agencies;<br/> <br/>
            c) the discontinuance, alteration and/or material modification to our Services, or any part thereof;<br/> <br/>
            d) unexpected technical or security issues and/or problems;<br/> <br/>
            e) any extended periods of inactivity;<br/> <br/>
            f) any engagement by you in any fraudulent or illegal activities; and/or<br/> <br/>
            g) the nonpayment of any associated fees that may be owed by you in connection with your
            http://www.liferoll.co account Services.<br/> <br/>
            Furthermore, you herein agree that any and all terminations, suspensions, discontinuances, and or
            limitations of access for cause shall be made at our sole discretion and that we shall not be liable
            to you or any other third party with regards to the termination of your account, associated email
            address and/or access to any of our Services.
            The termination of your account with http://www.liferoll.co shall include any and/or all of the
            following:<br/> <br/>
            a) the removal of any access to all or part of the Services offered within http://www.liferoll.co;<br/> <br/>
            b) the deletion of your password and any and all related information, files, and any such content<br/> <br/>
            that may be associated with or inside your account, or any part thereof; and<br/> <br/>
            c) the barring of any further use of all or part of our Services.<br/> <br/>
            <div className='body-header'>ADVERTISERS</div>
            Any correspondence or business dealings with, or the participation in any promotions of, advertisers
            located on or through our Services, which may include the payment and/or delivery of such related
            goods and/or Services, and any such other term, condition, warranty and/or representation associated
            with such dealings, are and shall be solely between you and any such advertiser. Moreover, you
            herein agree that Liferoll Inc shall not be held responsible or liable for any loss or damage of any
            nature or manner incurred as a direct result of any such dealings or as a result of the presence of
            such advertisers on our website or application.
            <div className='body-header'>LINKS</div>
            Either Liferoll Inc or any third parties may provide links to other websites and/or resources. Thus,
            you acknowledge and agree that we are not responsible for the availability of any such external
            sites or resources, and as such, we do not endorse nor are we responsible or liable for any content,
            products, advertising or any other materials, on or available from such third party sites or
            resources. Furthermore, you acknowledge and agree that Liferoll Inc shall not be responsible or
            liable, directly or indirectly, for any such damage or loss which may be a result of, caused or
            allegedly to be caused by or in connection with the use of or the reliance on any such content,
            goods or Services made available on or through any such site or resource.
            <div className='body-header'>PROPRIETARY RIGHTS</div>
            You do hereby acknowledge and agree that Liferoll Inc's Services and any essential software that may
            be used in connection with our Services ("Software") shall contain proprietary and confidential
            material that is protected by applicable intellectual property rights and other laws. Furthermore,
            you herein acknowledge and agree that any Content which may be contained in any advertisements or
            information presented by and through our Services or by advertisers is protected by copyrights,
            trademarks, patents or other proprietary rights and laws. Therefore, except for that which is
            expressly permitted by applicable law or as authorized by Liferoll Inc or such applicable licensor,
            you agree not to alter, modify, lease, rent, loan, sell, distribute, transmit, broadcast, publicly
            perform and/or created any plagiaristic works which are based on Liferoll Inc Services (e.g. Content
            or Software), in whole or part.
            Liferoll Inc herein has granted you personal, non-transferable and non-exclusive rights and/or
            license to make use of the object code or our Software on a single computer, as long as you do not,
            and shall not, allow any third party to duplicate, alter, modify, create or plagiarize work from,
            reverse engineer, reverse assemble or otherwise make an attempt to locate or discern any source
            code, sell, assign, sublicense, grant a security interest in and/or otherwise transfer any such
            right in the Software. Furthermore, you do herein agree not to alter or change the Software in any
            manner, nature or form, and as such, not to use any modified versions of the Software, including and
            without limitation, for the purpose of obtaining unauthorized access to our Services.
            Lastly, you also agree not to access or attempt to access our Services through any means other than
            through the interface which is provided by Liferoll Inc for use in accessing our Services.
            <div className='body-header'>WARRANTY DISCLAIMERS</div>
            YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:<br/> <br/>
            a) THE USE OF Liferoll Inc SERVICES AND SOFTWARE ARE AT THE SOLE RISK BY YOU. OUR SERVICES AND
            SOFTWARE SHALL BE PROVIDED ON AN "AS IS" AND/OR "AS AVAILABLE" BASIS. Liferoll Inc AND OUR
            SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY
            AND ALL WARRANTIES OF ANY KIND WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY
            IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.<br/> <br/>
            b) Liferoll Inc AND OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO
            SUCH WARRANTIES THAT <br/> <br/>(i) Liferoll INC. SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS;<br/> <br/> (ii)
            Liferoll Inc SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE;<br/> <br/> (iii) THAT
            SUCH RESULTS WHICH MAY BE OBTAINED FROM THE USE OF THE Liferoll Inc SERVICES OR SOFTWARE WILL BE
            ACCURATE OR RELIABLE; <br/> <br/>(iv) QUALITY OF ANY PRODUCTS, SERVICES, ANY INFORMATION OR OTHER MATERIAL
            WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH OUR SERVICES OR SOFTWARE WILL MEET YOUR
            EXPECTATIONS; AND <br/> <br/>(v) THAT ANY SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL BE CORRECTED.<br/> <br/>
            c) ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY WAY OF Liferoll Inc SERVICES OR
            SOFTWARE SHALL BE ACCESSED BY YOUR SOLE DISCRETION AND SOLE RISK, AND AS SUCH YOU SHALL BE SOLELY
            RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE
            TO YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING AND/OR DISPLAYING, OR FOR ANY LOSS OF DATA THAT
            COULD RESULT FROM THE DOWNLOAD OF ANY SUCH INFORMATION OR MATERIAL.<br/> <br/>
            d) NO ADVICE AND/OR INFORMATION, DESPITE WHETHER WRITTEN OR ORAL, THAT MAY BE OBTAINED BY YOU FROM
            Liferoll Inc OR BY WAY OF OR FROM OUR SERVICES OR SOFTWARE SHALL CREATE ANY WARRANTY NOT EXPRESSLY
            STATED IN THE TOS.<br/> <br/>
            e) A SMALL PERCENTAGE OF SOME USERS MAY EXPERIENCE SOME DEGREE OF EPILEPTIC SEIZURE WHEN EXPOSED TO
            CERTAIN LIGHT PATTERNS OR BACKGROUNDS THAT MAY BE CONTAINED ON A COMPUTER SCREEN OR WHILE USING OUR
            SERVICES. CERTAIN CONDITIONS MAY INDUCE A PREVIOUSLY UNKNOWN CONDITION OR UNDETECTED EPILEPTIC
            SYMPTOM IN USERS WHO HAVE SHOWN NO HISTORY OF ANY PRIOR SEIZURE OR EPILEPSY. SHOULD YOU, ANYONE YOU
            KNOW OR ANYONE IN YOUR FAMILY HAVE AN EPILEPTIC CONDITION, PLEASE CONSULT A PHYSICIAN IF YOU
            EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE USING OUR SERVICES: DIZZINESS, ALTERED VISION, EYE OR
            MUSCLE TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.<br/> <br/>
            <div className='body-header'>LIMITATION OF LIABILITY</div>
            YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT Liferoll Inc AND OUR SUBSIDIARIES, AFFILIATES,
            OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE,
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO,
            DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY PROFITS, GOODWILL, USE, DATA AND/OR OTHER INTANGIBLE
            LOSSES, EVEN THOUGH WE MAY HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES MAY OCCUR, AND
            RESULT FROM:<br/> <br/>
            a) THE USE OR INABILITY TO USE OUR SERVICE;<br/> <br/>
            b) THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES;<br/> <br/>
            c) UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR TRANSMISSIONS AND/OR DATA;<br/> <br/>
            d) STATEMENTS OR CONDUCT OF ANY SUCH THIRD PARTY ON OUR SERVICE;<br/> <br/>
            e) AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.<br/> <br/>
            <div className='body-header'>RELEASE</div>
            In the event you have a dispute, you agree to releaseLiferoll Inc (and its officers, directors,
            employees, agents, parent subsidiaries, affiliates, co-branders, partners and any other third
            parties) from claims, demands and damages (actual and consequential) of every kind and nature, known
            and unknown, suspected or unsuspected, disclosed and undisclosed, arising out of or in any way
            connected to such dispute.
            <div className='body-header'>SPECIAL ADMONITION RELATED TO FINANCIAL MATTERS</div>
            Should you intend to create or to join any service, receive or request any such news, messages,
            alerts or other information from our Services concerning companies, stock quotes, investments or
            securities, please review the above Sections Warranty Disclaimers and Limitations of Liability
            again. In addition, for this particular type of information, the phrase "Let the investor beware" is
            appropriate. Liferoll Inc's content is provided primarily for informational purposes, and no content
            that shall be provided or included in our Services is intended for trading or investing purposes.
            Liferoll Inc and our licensors shall not be responsible or liable for the accuracy, usefulness or
            availability of any information transmitted and/or made available by way of our Services, and shall
            not be responsible or liable for any trading and/or investment decisions based on any such
            information.
            <div className='body-header'>EXCLUSION AND LIMITATIONS</div>
            THERE ARE SOME JURISDICTIONS WHICH DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
            LIMITATION OF EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. THEREFORE, SOME OF THE
            ABOVE LIMITATIONS OF SECTIONS WARRANTY DISCLAIMERS AND LIMITATION OF LIABILITY MAY NOT APPLY TO YOU.
            <div className='body-header'>THIRD PARTY BENEFICIARIES</div>
            You herein acknowledge, understand and agree, unless otherwise expressly provided in this TOS, that
            there shall be no third-party beneficiaries to this agreement.
            <div className='body-header'>NOTICE</div>
            Liferoll Inc may furnish you with notices, including those with regards to any changes to the TOS,
            including but not limited to email, regular mail, MMS or SMS, text messaging, postings on our
            website or application Services, or other reasonable means currently known or any which may be herein after
            developed. Any such notices may not be received if you violate any aspects of the TOS by accessing
            our Services in an unauthorized manner. Your acceptance of this TOS constitutes your agreement that
            you are deemed to have received any and all notices that would have been delivered had you accessed
            our Services in an authorized manner.
            <div className='body-header'>TRADEMARK INFORMATION</div>
            You herein acknowledge, understand and agree that all of theLiferoll Inc trademarks, copyright,
            trade name, service marks, and other Liferoll Inc logos and any brand features, and/or product and
            service names are trademarks and as such, are and shall remain the property of Liferoll Inc. You
            herein agree not to display and/or use in any manner theLiferoll Inc logo or marks without obtaining
            Liferoll Inc's prior written consent.
            <div className='body-header'>COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS NOTICE
                PROCEDURES</div>
            Liferoll Inc will always respect the intellectual property of others, and we ask that all of our
            users do the same. With regards to appropriate circumstances and at its sole discretion, Liferoll
            Inc may disable and/or terminate the accounts of any user who violates our TOS and/or infringes the
            rights of others. If you feel that your work has been duplicated in such a way that would constitute
            copyright infringement, or if you believe your intellectual property rights have been otherwise
            violated, you should provide to us the following information:<br/> <br/>
            a) The electronic or the physical signature of the individual that is authorized on behalf of the
            owner of the copyright or other intellectual property interest;<br/> <br/>
            b) A description of the copyrighted work or other intellectual property that you believe has been
            infringed upon;<br/> <br/>
            c) A description of the location of the site which you allege has been infringing upon your work;<br/> <br/>
            d) Your physical address, telephone number, and email address;<br/> <br/>
            e) A statement, in which you state that the alleged and disputed use of your work is not authorized
            by the copyright owner, its agents or the law;<br/> <br/>
            f) And finally, a statement, made under penalty of perjury, that the aforementioned information in
            your notice is truthful and accurate, and that you are the copyright or intellectual property owner,
            representative or agent authorized to act on the copyright or intellectual property owner's behalf.
            The Liferoll Inc Agent for notice of claims of copyright or other intellectual property infringement
            can be contacted as follows:<br/> <br/>
            Mailing Address:<br/>
            Jay Patel<br/>
            548 Market St<br/>
            PMB 46452<br/>
            San Francisco, California 94104<br/>
            US<br/><br/>
            Phone: (415) 528-6901<br/><br/>
            E-mail: help@liferoll.co<br/>
            <div className='body-header'>GENERAL INFORMATION</div>
            <div className='body-header'>ENTIRE AGREEMENT</div>
            This TOS constitutes the entire agreement between you andLiferoll Inc and shall govern the use of
            our Services, superseding any prior version of this TOS between you and us with respect to Liferoll
            Inc Services. You may also be subject to additional terms and conditions that may apply when you use
            or purchase certain other Liferoll Inc Services, affiliate Services, third- party content or
            third-party software.
            <div className='body-header'>CHOICE OF LAW AND FORUM</div>
            It is at the mutual agreement of both you andLiferoll Inc with regard to the TOS that the
            relationship between the parties shall be governed by the laws of the state of Delaware without
            regard to its conflict of law provisions and that any and all claims, causes of action and/or
            disputes, arising out of or relating to the TOS, or the relationship between you andLiferoll Inc.,
            shall be filed within the courts having jurisdiction within the County of, Delaware or the U.S.
            District Court located in said state. You and Liferoll Inc agree to submit to the jurisdiction of
            the courts as previously mentioned, and agree to waive any and all objections to the exercise of
            jurisdiction over the parties by such courts and to venue in such courts.
            <div className='body-header'>WAIVER AND SEVERABILITY OF TERMS</div>
            At any time, should Liferoll Inc fail to exercise or enforce any right or provision of the TOS, such
            failure shall not constitute a waiver of such right or provision. If any provision of this TOS is
            found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties' intentions as reflected in the provision, and
            the other provisions of the TOS remain in full force and effect.
            <div className='body-header'>NO RIGHT OF SURVIVORSHIP NON TRANSFERABILITY</div>
            You acknowledge, understand and agree that your account is non-transferable and any rights to your
            ID and/or contents within your account shall terminate upon your death. Upon receipt of a copy of a
            death certificate, your account may be terminated and all contents therein permanently deleted.
            <div className='body-header'>STATUTE OF LIMITATIONS</div>
            You acknowledge, understand and agree that regardless of any statute or law to the contrary, any
            claim or action arising out of or related to the use of our Services or the TOS must be filed within
            2 year(s) after said claim or cause of action arose or shall be forever barred.
            <div className='body-header'>VIOLATIONS</div>
            Please report any and all violations of this TOS toLiferoll Inc as follows:
            Email: help@liferoll.co
        </div>
    )
}
