/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import 'fontsource-roboto';
import './react-stacked-carousel/dist/index.css';
import './App.css';

import logo from './assets/logo_400x400.png';



function NavBar() {
    return (
        <div className="navbar">
            <Link to="/">
                <img src={logo} className="logo" alt="logo" />
            </Link>
            <a href="https://apps.apple.com/us/app/liferoll/id1540436603">
                <div className="help_button_nav">Try Now</div>
            </a>
        </div>
    );
}

export default NavBar;
