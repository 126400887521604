import React from "react";

export function PrivacyPolicy() {
  return (
    <div className="legal-body">
      Effective date: September 8, 2020 Liferoll ("us", "we", or "our") operates
      the Liferoll mobile application (the "Service"). This page informs you of
      our policies regarding the collection, use, and disclosure of personal
      data when you use our Service and the choices you have associated with
      that data. Our Privacy Policy for Liferoll is managed through Free Privacy
      Policy. We use your data to provide and improve the Service. By using the
      Service, you agree to the collection and use of information in accordance
      with this policy. Unless otherwise defined in this Privacy Policy, terms
      used in this Privacy Policy have the same meanings as in our Terms and
      Conditions.
      <div className="body-header">Information Collection And Use</div>
      We collect several different types of information for various purposes to
      provide and improve our Service to you.
      <div className="body-header">Types of Data Collected</div>
      <div className="body-header">Personal Data</div>
      While using our Service, we may ask you to provide us with certain
      personally identifiable information that can be used to contact or
      identify you ("Personal Data"). Personally identifiable information may
      include, but is not limited to:
      <br /> <br />
      Email address
      <br /> <br />
      First name and last name
      <br /> <br />
      Phone number
      <br /> <br />
      Cookies and Usage Data
      <br /> <br />
      Photos and videos shared privately or publicly on the service
      <div className="body-header">Usage Data</div>
      When you access the Service by or through a mobile device, we may collect
      certain information automatically, including, but not limited to, the type
      of mobile device you use, your mobile device unique ID, the IP address of
      your mobile device, your mobile operating system, the type of mobile
      Internet browser you use, unique device identifiers and other diagnostic
      data ("Usage Data").
      <div className="body-header">Tracking & Cookies Data</div>
      We use cookies and similar tracking technologies to track the activity on
      our Service and hold certain information. Cookies are files with small
      amount of data which may include an anonymous unique identifier. Cookies
      are sent to your browser from a website and stored on your device.
      Tracking technologies also used are beacons, tags, and scripts to collect
      and track information and to improve and analyze our Service. You can
      instruct your browser to refuse all cookies or to indicate when a cookie
      is being sent. However, if you do not accept cookies, you may not be able
      to use some portions of our Service. Examples of Cookies we use: Session
      Cookies. We use Session Cookies to operate our Service. Preference
      Cookies. We use Preference Cookies to remember your preferences and
      various settings. Security Cookies. We use Security Cookies for security
      purposes.
      <div className="body-header">Phone Contacts Data</div>
      We receive personal data (including contact information) about you when
      others import or sync their contacts with our Services, associate their
      contacts with Member profiles, or send messages using our Services
      (including invites or connection requests).
      <div className="body-header">Use of Data</div>
      Liferoll uses the collected data for various purposes:
      <br /> <br />
      To provide and maintain the Service
      <br /> <br />
      To notify you about changes to our Service
      <br /> <br />
      To allow you to participate in interactive features of our Service when
      you choose to do so
      <br /> <br />
      To provide customer care and support
      <br /> <br />
      To provide analysis or valuable information so that we can improve the
      Service
      <br /> <br />
      To monitor the usage of the Service
      <br /> <br />
      To detect, prevent and address technical issues
      <br /> <br />
      To suggest other users who you may already know to be added as friends
      <br /> <br />
      <div className="body-header">Transfer Of Data</div>
      Your information, including Personal Data, may be transferred to — and
      maintained on — computers located outside of your state, province, country
      or other governmental jurisdiction where the data protection laws may
      differ than those from your jurisdiction. If you are located outside
      United States and choose to provide information to us, please note that we
      transfer the data, including Personal Data, to United States and process
      it there. Your consent to this Privacy Policy followed by your submission
      of such information represents your agreement to that transfer. Liferoll
      will take all steps reasonably necessary to ensure that your data is
      treated securely and in accordance with this Privacy Policy and no
      transfer of your Personal Data will take place to an organization or a
      country unless there are adequate controls in place including the security
      of your data and other personal information.
      <div className="body-header">Disclosure Of Data</div>
      <div className="body-header">Legal Requirements</div>
      Liferoll may disclose your Personal Data in the good faith belief that
      such action is necessary to: To comply with a legal obligation To protect
      and defend the rights or property of Liferoll To prevent or investigate
      possible wrongdoing in connection with the Service To protect the personal
      safety of users of the Service or the public To protect against legal
      liability
      <div className="body-header">Security Of Data</div>
      The security of your data is important to us, but remember that no method
      of transmission over the Internet, or method of electronic storage is 100%
      secure. While we strive to use commercially acceptable means to protect
      your Personal Data, we cannot guarantee its absolute security.
      <div className="body-header">Service Providers</div>
      We may employ third party companies and individuals to facilitate our
      Service ("Service Providers"), to provide the Service on our behalf, to
      perform Service-related services or to assist us in analyzing how our
      Service is used. These third parties have access to your Personal Data
      only to perform these tasks on our behalf and are obligated not to
      disclose or use it for any other purpose.
      <div className="body-header">Analytics</div>
      We may use third-party Service Providers to monitor and analyze the use of
      our Service.
      <br /> <br />
      Google Analytics
      <br /> <br />
      Google Analytics is a web analytics service offered by Google that tracks
      and reports website traffic. Google uses the data collected to track and
      monitor the use of our Service. This data is shared with other Google
      services.
      For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en
      <div className="body-header">Links To Other Sites</div>
      Our Service may contain links to other sites that are not operated by us.
      If you click on a third party link, you will be directed to that third
      party's site. We strongly advise you to review the Privacy Policy of every
      site you visit. We have no control over and assume no responsibility for
      the content, privacy policies or practices of any third party sites or
      services.
      <div className="body-header">Children's Privacy</div>
      Our Service does not address anyone under the age of 13 ("Young Children"). We
      do not knowingly collect personally identifiable information from anyone
      under the age of 13. If you are a parent or guardian and you are aware
      that your Young Children has provided us with Personal Data, please contact us.
      If we become aware that we have collected Personal Data from Young Children
      without verification of parental consent, we take steps to remove that
      information from our servers. 
      For anyone between the ages of 13-18 ("Children"), we collect data just as we do for the general audiences we serve.
      We continuously strive to minimize the data we collect to only what's necessary.
      Parents can request a data archive of their Children's activity, or direct us to delete it anytime via their Children's account.
      <div className="body-header">Changes To This Privacy Policy</div>
      We may update our Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page. We will let
      you know via email and/or a prominent notice on our Service, prior to the
      change becoming effective and update the "effective date" at the top of
      this Privacy Policy. You are advised to review this Privacy Policy
      periodically for any changes. Changes to this Privacy Policy are effective
      when they are posted on this page.
      <div className="body-header">Contact Us</div>
      If you have any questions about this Privacy Policy, please contact us: By
      email: help@liferoll.co
    </div>
  );
}
