import React, {useRef, useState} from 'react';
import '../../styles/Account.css';
import { useParams } from 'react-router-dom';
import { TextInput } from '../common/TextInput';
import { LiferollButton } from '../common/LiferollButton';
import Checkmark from '../../assets/checkmark-flat.png'
import { API_PUBLIC_URL, getResponseType, isIOS, makePost } from '../common/utils';


const MIN_PASSWORD_LENGTH = 8

const SET_PASSWORD_API_URL = `${API_PUBLIC_URL}/user/set-password`

type Props = {

}
interface ParamTypes {
    tokenId: string
}
  
function SetPassword(props: Props) {
    // ---------------- Declare state, param and ref variables ---------------- //
    const { tokenId } = useParams<ParamTypes>()

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccessful, setIsSuccessful] = useState(false)
    const [isFailed, setIsFailed] = useState(false)

    const [desiredPasswordError, setDesiredPasswordError] = useState('')
    const [desiredPassword, setDesiredPassword] = useState('')

    const [verifyPasswordError, setVerifyPasswordError] = useState('')
    const [verifyPassword, setVerifyPassword] = useState('')

    const verifyPasswordInputRef = useRef<any>(null)

    // ---------------- Define hooks and event handlers ---------------- //
    const onPasswordChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
        const text = event.target.value
        setDesiredPassword(text)
        if (text.length) setDesiredPasswordError('')
    }
    const onVerifyPasswordChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
        const text = event.target.value
        setVerifyPassword(text)
        if (text.length) setVerifyPasswordError('')
    }

    const validatePassword = () => {
        if (desiredPassword.length < MIN_PASSWORD_LENGTH) {
            setDesiredPasswordError(`Password must be at least ${MIN_PASSWORD_LENGTH} characters`)
            return false
        }
        setDesiredPasswordError('')
        return true
    }
    const validateVerifyPassword = () => {
        if (verifyPassword !== desiredPassword) {
            setVerifyPasswordError(`Passwords don't match`)
            return false
        }
        setVerifyPasswordError('')
        return true
    }

    const onPasswordEnterPress = () => {
        if (validatePassword()) verifyPasswordInputRef.current?.focus()
    }

    const onVerifyPasswordEnterPress = () => {
        if (validateVerifyPassword()) onSubmit()
    }

    const onSubmit = async () => {
        if (!validatePassword() || !validateVerifyPassword()) return

        setIsLoading(true)
        try {
            const response = await makePost(SET_PASSWORD_API_URL, {
                tokenId,
                password: desiredPassword,
            })
            const responseType = getResponseType(response)
            if (responseType == '5XX') {
                alert('Oops. Something went wrong processing your request.\nIf issues persist please contact us.')
            } else if (responseType == '4XX') {
                setIsFailed(true)
            } else if (responseType == '2XX') {
                setIsSuccessful(true)
            } else {
                // Should never happen...
                alert('Oops. Something went wrong processing your request.\nIf issues persist please contact us.')
            }
        } catch(e) {
            // TODO in-app modal to handle this shit
            alert('Oops. Something went wrong processing your request.\nIf issues persist please contact us.')
        }
        setIsLoading(false)
    }

    // ---------------- Render elements ---------------- //

    {/***** Initial form to set password *****/}
    const setPasswordForm = (
        <div className="account-container">
            <h1>Let's set your password</h1>
            {/***** Password *****/}
            <TextInput
                label={'Password'}
                placeholder={''}
                helperText={desiredPasswordError}
                type={'password'}
                autoFocus={true}
                onChange={onPasswordChange}
                onEnterPress={onPasswordEnterPress}
                disabled={isLoading}
            />
            {/***** Confirm Password *****/}
            <TextInput
                ref={verifyPasswordInputRef}
                label={'Verify Password'}
                placeholder={''}
                helperText={verifyPasswordError}
                type={'password'}
                onChange={onVerifyPasswordChange}
                onEnterPress={onVerifyPasswordEnterPress}
                disabled={isLoading}
            />
            {/***** Spacing *****/}
            <div
                style={{
                    marginBottom: '48px',
                }}
            ></div>
            {/***** Submit button *****/}
            <LiferollButton
                label="Set Password"
                onPress={onSubmit}
                disabled={!!desiredPasswordError || !!verifyPasswordError || !desiredPassword || !verifyPassword || isLoading}
            />
        </div>
    )

    {/***** Password set successful view *****/}
    const passwordSetConfirmation = (
        <div className="account-container">
            <img
                src={Checkmark}
                height={60}
                style={{
                    opacity: 0.9
                }}
            />
            <h1
                style={{
                    fontWeight: 'normal',
                }}
            >Your password's been updated!</h1>
            {isIOS ? (
                <div
                    style={{
                        display: 'block',
                        width: '90%',
                        marginTop: '12px',
                    }}
                >
                    <LiferollButton
                        label={'Open App'}
                        onPress={() => window.open('https://www.liferoll.co/open-app/forgot-password')}
                    />
                </div>
            ) : <p>Simply open the app and log in.</p>}
            
        </div>
    )

    {/***** Link expired view *****/}
    const linkExpiredPrompt = (
        <div
            className="account-container"
            style={{
                paddingTop: '80px',
                paddingBottom: '80px',
            }}
        >
            <h1>
                Oops...
                {isIOS ? <br/> : ' '}
                This link may have expired.
            </h1>
            {isIOS ? (
                <div
                    style={{
                        display: 'block',
                        width: '90%',
                        marginBottom: '24px',
                        marginTop: '12px',
                    }}
                >
                    <hr
                        style={{
                            backgroundColor: '#2B2B2B',
                            height: '1px',
                            marginBottom: '32px',
                            width: '100%',
                            border: 'none',
                        }}
                    />
                    <p>Request another one with the app.</p>
                    <LiferollButton
                        label={'Open App'}
                        // onPress={() => window.open('https://www.liferoll.co/open-app/forgot-password')}
                        onPress={() => window.open('liferoll://open-app/forgot-password')}
                    />
                </div>
            ) : <p>Use the app to get another one.</p>}
        </div>
    )

    {/***** Render based on state of flow *****/}
    return (<div
        className={'center-box-wrapper'}
    >
        {isSuccessful ? passwordSetConfirmation : isFailed ? linkExpiredPrompt : setPasswordForm}
    </div>)
}

export default SetPassword;
