import React from 'react'
import one from '../../assets/delete_steps/1.jpg'
import two from '../../assets/delete_steps/2.jpg'
import three from '../../assets/delete_steps/3.jpg'

export const DeleteSteps = () => {

    const onDeleteAccount = async () => {
        // prompt user their username and password
        const username = prompt('Please enter your username')
        const password = prompt('Please enter your password')
        if (username && password) {
            // temp, sleep for 1 second to simulate a request
            await new Promise(resolve => setTimeout(resolve, 1000))
            // send a request to the server to delete the account
            // fetch('/api/deleteAccount', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({ username, password })
            // }).then(res => {
            //     if (res.status === 200) {
                    // alert('Your account has been deleted')
            //     } else {
                    alert('There was an error deleting your account, please verify your credentials, or contact us help@liferoll.co')
            //     }
            // })
        } else {
            alert('Invalid username and password')
        }
    }

    return <div style={{ color: 'white', padding: 36, }}>
        <h1>How to delete your Liferoll account</h1>
        <h2>Delete it via web</h2>
        <p><a href="#" onClick={onDeleteAccount} style={{ textDecoration: 'underline' }}>Click here</a> to delete your account.</p>
        <h2>If you have access to the app</h2>
        <p style={{ fontWeight: 'bold' }}>1. Go to the account settings page in the app</p>
        <img style={{ maxWidth: '80vw', borderRadius: 8, marginTop: 8, borderColor: '#333', borderWidth: 1, borderStyle: 'solid' }} src={one} />
        <div style={{ width: '100%', height: 1, marginTop: 36, marginBottom: 36, backgroundColor: '#333' }} />
        <p style={{ fontWeight: 'bold' }}>2. Select more actions</p>
        <img style={{ maxWidth: '80vw', borderRadius: 8, marginTop: 8, borderColor: '#333', borderWidth: 1, borderStyle: 'solid' }} src={two} />
        <div style={{ width: '100%', height: 1, marginTop: 36, marginBottom: 36, backgroundColor: '#333' }} />
        <p style={{ fontWeight: 'bold' }}>3. Select 'Delete Account'</p>
        <img style={{ maxWidth: '80vw', borderRadius: 8, marginTop: 8, borderColor: '#333', borderWidth: 1, borderStyle: 'solid' }} src={three} />

        <h2>If you don't have access to the app</h2>
        <p>Using the email that's associated with your Liferoll account, please send an email to <a href="mailto:help@liferoll.co" style={{ textDecoration: 'underline' }}>help@liferoll.co</a> with the subject 'Delete Account'</p>
        <p>If your Liferoll account has not yet been verified with an email, you can reset your login using your phone number and the app. If this is not possible, please contact us at <a href="mailto:help@liferoll.co" style={{ textDecoration: 'underline' }}>help@liferoll.co</a> and we will attempt other means to verify your ownership over your account.</p>
        <h2 id="delete-partial">If you want to only delete some of your data but not all of it.</h2>
        <p>When you're logged in to the app you can freely delete any rolls, posts, messages and other media that you have shared to Liferoll.</p>


        <h2>Learn more</h2>
        <p>Please visit our <a href="/legal/privacy" style={{ textDecoration: 'underline' }}>privacy policy page</a> to learn more about account deletion.</p>
    </div>
}