import React from "react";

export function ContentPolicy(){
    return(
        <div className="legal-body">
                As a user or member of the Site, you herein acknowledge, understand and agree that all information,
                text, software, data, photographs, music, video, messages, tags or any other content, whether it is
                publicly or privately posted and/or transmitted, is the expressed sole responsibility of the
                individual from whom the content originated. In short, this means that you are solely responsible
                for any and all content posted, uploaded, emailed, transmitted or otherwise made available by way of
                the Liferoll Services, and as such, we do not guarantee the accuracy, integrity or quality of such
                content. It is expressly understood that by use of our Services, you may be exposed to content
                including, but not limited to, any errors or omissions in any content posted, and/or any loss or
                damage of any kind incurred as a result of the use of any content posted, emailed, transmitted or
                otherwise made available by Liferoll. Furthermore, you herein agree not to make use of Liferoll Inc's
                Services for the purpose of:  <br/> <br/>
                a) uploading, posting, emailing, transmitting, or otherwise making available any content that shall
                be deemed unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene,
                libelous, or invasive of another's privacy or which is hateful, and/or racially, ethnically, or
                otherwise objectionable;  <br/> <br/>
                b) causing harm to minors in any manner whatsoever;<br/> <br/>
                c) impersonating any individual or entity, including, but not limited to, any Liferoll officials,
                forum leaders, guides or hosts or falsely stating or otherwise misrepresenting any affiliation with
                an individual or entity;<br/> <br/>
                d) forging captions, headings or titles or otherwise offering any content that you personally have
                no right to pursuant to any law nor having any contractual or fiduciary relationship with;<br/> <br/>
                e) uploading, posting, emailing, transmitting or otherwise offering any such content that may
                infringe upon any patent, copyright, trademark, or any other proprietary or intellectual rights of
                any other party;<br/> <br/>
                f) uploading, posting, emailing, transmitting or otherwise offering any content that you do not
                personally have any right to offer pursuant to any law or in accordance with any contractual or
                fiduciary relationship;<br/> <br/>
                g) uploading, posting, emailing, transmitting, or otherwise offering any unsolicited or unauthorized
                advertising, promotional flyers, "junk mail," "spam," or any other form of solicitation, except in
                any such areas that may have been designated for such purpose;<br/> <br/>
                h) uploading, posting, emailing, transmitting, or otherwise offering any source that may contain a
                software virus or other computer code, any files and/or programs which have been designed to
                interfere, destroy and/or limit the operation of any computer software, hardware, or
                telecommunication equipment;<br/> <br/>
                i) disrupting the normal flow of communication, or otherwise acting in any manner that would
                negatively affect other users' ability to participate in any real time interactions;<br/> <br/>
                j) interfering with or disrupting any Liferoll Inc Services, servers and/or networks that may be
                connected or related to our website, including, but not limited to, the use of any device software
                and/or routine to bypass the robot exclusion headers;<br/> <br/>
                k) intentionally or unintentionally violating any local, state, federal, national or international
                law, including, but not limited to, rules, guidelines, and/or regulations decreed by the U.S.
                Securities and Exchange Commission, in addition to any rules of any nation or other securities
                exchange, that would include without limitation, the New York Stock Exchange, the American Stock
                Exchange, or the NASDAQ, and any regulations having the force of law;<br/> <br/>
                l) providing informational support or resources, concealing and/or disguising the character,
                location, and or source to any organization delegated by the United States government as a "foreign
                terrorist organization" in accordance to Section 219 of the Immigration Nationality Act;<br/> <br/>
                m) "stalking" or with the intent to otherwise harass another individual; and/or<br/> <br/>
                n) collecting or storing of any personal data relating to any other member or user in connection
                with the prohibited conduct and/or activities which have been set forth in the aforementioned
                paragraphs.<br/> <br/>
                Liferoll Inc herein reserves the right to pre-screen, refuse and/or delete any content currently
                available through our Services. In addition, we reserve the right to remove and/or delete any such
                content that would violate the TOS or which would otherwise be considered offensive to other
                visitors, users and/or members.
                Liferoll Inc herein reserves the right to access, preserve and/or disclose member account
                information and/or content if it is requested to do so by law or in good faith belief that any such
                action is deemed reasonably necessary for:<br/> <br/>
                a) compliance with any legal process;<br/> <br/>
                b) enforcement of the TOS;<br/> <br/>
                c) responding to any claim that therein contained content is in violation of the rights of any third
                party;<br/> <br/>
                d) responding to requests for customer service; or<br/> <br/>
                e) protecting the rights, property or the personal safety ofLiferoll Inc, its visitors, users and
                members, including the general public.<br/> <br/>
                Liferoll Inc herein reserves the right to include the use of security components that may permit
                digital information or material to be protected, and that such use of information and/or material is
                subject to usage guidelines and regulations established by Liferoll Inc or any other content
                providers supplying content services to Liferoll Inc. You are hereby prohibited from making any
                attempt to override or circumvent any of the embedded usage rules in our Services. Furthermore,
                unauthorized reproduction, publication, distribution, or exhibition of any information or materials
                supplied by our Services, despite whether done so in whole or in part, is expressly Prohibited.
        </div>
    )
}
