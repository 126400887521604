import React from 'react';
import '../styles/Help.css';

function Help() {
    return (
            <div className="help">
                <div className="help-container">
                   <div style={{marginBottom:20}}>
                       We're here to help. Tell us what's up!
                   </div>
                    <div className="email-button">
                        <a style={{textDecoration:'none', display: 'block', marginTop: '24px'}} href="https://ig.me/m/liferollhq" target="_blank">DM us on Instagram</a>
                        <a style={{textDecoration:'none', display: 'block', marginTop: '24px'}} href="https://twitter.com/liferollhq" target="_blank">DM us on X</a>
                        <a style={{textDecoration:'none', display: 'block', marginTop: '24px'}} href="mailto:help@liferoll.co">help@liferoll.co</a>
                    </div>
                </div>
            </div>
    )
}

export default Help;
