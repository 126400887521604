import scrollSnapPolyfill from 'css-scroll-snap-polyfill'
import React, {useCallback, useEffect, useRef, useState} from 'react';
import iosDownloadSVG from '../assets/download-ios.svg'
import androidDownloadPNG from '../assets/download-android.png'
import qrCodeWebsite from '../assets/qrcode_website.png'
// import clip1 from '../assets/clip1.mp4'
// import clip2 from '../assets/clip2.mp4'
// import clip3 from '../assets/clip3.mp4'
// import clip3 from '../assets/video_demo_2.mp4'
// import clip2 from '../assets/video_demo_1.mp4'
import clip1 from '../assets/clip1.mp4'
// import groupStoryImage from '../assets/group_stories.jpg'
// import shareHobbiesImage from '../assets/share_hobby.jpg'
// import goalStoryImage from '../assets/goal_story.jpg'
// import discoverStory from '../assets/discover.jpg'
// import chatConvo from '../assets/chat.jpg'
// import privacyImage from '../assets/privacy.jpg'
// import ChevronLeft from '@material-ui/icons/ChevronLeft'
// import ChevronRight from '@material-ui/icons/ChevronRight'
// import storyPropOngoing from '../assets/story_prop_ongoing.png'
// import storyPropInfinite from '../assets/story_prop_infinite_stories.png'
// import storyPropPrivacy from '../assets/story_prop_privacy.png'
// import groupStoryImage from '../assets/demo_screens/groupsv2.jpg'
// import gamesImage from '../assets/demo_screens/gamesv2.jpg'
// import montagesImage from '../assets/demo_screens/montages.jpg'
// import highlightsImage from '../assets/demo_screens/collagev2.jpg'
// import privacyImage from '../assets/demo_screens/profilev2.jpg'
// import clip4 from '../assets/clip4.mov'
import discoverimg from '../assets/demo_screens_4/discover.jpg'
import storiesoflifeimg from '../assets/demo_screens_4/stories_of_life.jpg'
import musicimg from '../assets/demo_screens_4/music.jpg'
import fitsimg from '../assets/demo_screens_4/fits.jpg'
import recapimg from '../assets/demo_screens_4/recap.jpg'

import '../react-stacked-carousel/dist/index.css';
import '../styles/Splash.css';

//https://github.com/saadqbal/react-stacked-carousel/issues/1
import {StackedCarousel} from '../react-stacked-carousel'
import AndroidSignup from './modals/AndroidSignup';
import DownloadApp from './modals/DownloadApp';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar';

function Splash({shouldShowContent, setShouldShowContent, toggleChildVideosLoaded}: any) {
    const [numberOfVideoLoads, changeNumberOfVideoLoads] = useState<number>(1)

    const shouldShowContentRef = useRef(shouldShowContent)
    shouldShowContentRef.current = shouldShowContent

    const whatsPossibleStartRef = useRef<HTMLDivElement>(null)

    // const [shouldShowAndroidSignupModal, setShouldShowAndroidSignupModal] = useState(false)
    const [shouldShowDownloadAppModal, setShouldShowDownloadAppModal] = useState(false)

    const video1 = useRef<HTMLVideoElement>(null)
    const video2 = useRef<HTMLVideoElement>(null)
    const video3 = useRef<HTMLVideoElement>(null)
    // const video4 = useRef<HTMLVideoElement>(null)
    const carouselRef = useRef();

    useEffect(() => {
        scrollSnapPolyfill()
    }, [])
    const handleVideoLoad = () => {
        changeNumberOfVideoLoads(numberOfVideoLoads + 1)
        if (numberOfVideoLoads === 1) {
            toggleChildVideosLoaded(true)
            setTimeout(() => {
                setShouldShowContent(true)
                video1.current?.play()
            }, 300)
        }
    }
    const rotateRight = () => {
        // @ts-ignore
        carouselRef.current && carouselRef.current.rotateRightExternally()
    }

    const handleVideoEnd = () => {
        rotateRight()
    }

    const handleCardChange = useCallback(
        (event: any) => {
            const currentIndex = event.currentIndex;
            if (video1.current && video2.current && video3.current) { //&& video4.current) {
                video1.current.pause()
                video2.current.pause()
                video3.current.pause()
                // video4.current.pause()

                if (!shouldShowContentRef.current) {
                    setTimeout(() => {
                        handleCardChange(event)
                    }, 1000)
                    return
                }
                if (currentIndex === 0) {
                    video1.current.play().catch(() => {
                    })
                } else if (currentIndex === 1) {
                    video2.current.play().catch(() => {
                    })
                } else if (currentIndex === 2) {
                    video3.current.play().catch(() => {
                    })
                }
                // else if (currentIndex === 3) {
                //     video4.current.play().catch(() => {
                //     })
                // }
            }
        },
        [shouldShowContent]
    )

    const [isScrolled, setIsScrolled] = useState(false)
    const isScrolledRef = useRef(isScrolled)
    isScrolledRef.current = isScrolled
    useEffect(() => {
        const onScroll = () => {
            const currPos = window.scrollY
            if (currPos > 10) {
                if (!isScrolledRef.current) setIsScrolled(true)
            } else {
                if (isScrolledRef.current) setIsScrolled(false)
            }
        }
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    } , [])

    return (
        <div className="splash">
            <div className="splash-container">
                <div style={{ width: '100%', scrollSnapAlign: 'start' }}>
                    <NavBar/>
                </div>
                <div className="first-section">
                    <h1 className='header'>
                        simple &amp; stunning photo journals.
                    </h1>
                    <div className='content'>
                        {/*@ts-ignore*/}
                        {/* <StackedCarousel
                            autoRotate={false}
                            ref={carouselRef}
                            onCardChange={handleCardChange}
                            containerClassName={"carousel"}
                            leftButton={<span className='carousel-button-left'>
                            <ChevronLeft />
                        </span>}
                            rightButton={<span className='carousel-button-right'>
                            <ChevronRight />
                        </span>}
                        > */}
                            <div className='carousel' key={'child1'}>
                                <video ref={video1}
                                       autoPlay
                                       loop
                                       playsInline
                                       muted
                                       className="video"
                                       src={clip1}
                                       onLoadedData={handleVideoLoad}
                                       onEnded={handleVideoEnd}
                                />
                            </div>
                            {/* <div key={'child2'}>
                                <video
                                    ref={video2}
                                    autoPlay
                                    playsInline
                                    muted
                                    className="video"
                                    src={clip3}
                                    onLoadedData={handleVideoLoad}
                                    onEnded={handleVideoEnd}
                                />
                            </div>
                            <div key={'child3'}>
                                <video
                                    ref={video3}
                                    autoPlay
                                    playsInline
                                    muted
                                    className="video"
                                    src={clip2}
                                    onLoadedData={handleVideoLoad}
                                    onEnded={handleVideoEnd}
                                />
                            </div> */}
                            {/* <div key={'child4'}>
                            <video
                                ref={video4}
                                autoPlay
                                playsInline
                                muted
                                className="video"
                                src={clip4}
                                onLoadedData={handleVideoLoad}
                                onEnded={handleVideoEnd}
                            />
                        </div> */}
                        {/* </StackedCarousel> */}
                        <div className="content-right">
                            {/* <div className="content-right-heading">
                                <div>Get Liferoll</div>
                                <span>Free on iPhone and Android</span>
                            </div> */}
                            {/*-------- Mobile only --------*/}
                            <div className="header-mobile-container">
                                <div className="header-mobile">
                                    {/* discover liferoll. */}
                                        simple &amp; stunning<br />photo journals.
                                    <div className='mobile-divider'/>
                                </div>
                                {/* <span>Get Liferoll</span> */}
                            </div>
                            {/*-----------------------------*/}
                            <div className='qrcode-container'>
                                <img src={qrCodeWebsite} className='qrcode' alt="QR Code"/>
                                <span>scan with phone camera</span>
                            </div>
                            <div className="download-buttons-container">
                                <a
                                    href="https://apps.apple.com/us/app/liferoll/id1540436603"
                                    style={{marginRight: '12px'}}
                                >
                                    <img className='download-button' style={{padding: 0, border: "none"}}
                                         src={iosDownloadSVG} alt="iOS Download"/>
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.liferoll.prana"
                                    style={{marginRight: '12px'}}
                                >
                                    <img className='download-button' style={{padding: 0, border: "none"}}
                                         src={androidDownloadPNG} alt="Android Download" height={40} />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className='scroll-down-indicator' style={{ opacity: isScrolled ? 0 : 1 }}>
                        <p className='scroll-down-label'>scroll to learn more<br/><span style={{ top: -4, position: 'relative' }}>⌄</span> </p>
                    </div> */}
                </div>

                


                <div className="footer" style={{ scrollSnapAlign: 'start' }}>
                    <Link to="/help">
                        <span>Help</span>
                    </Link>
                    <Link to="/legal/terms">
                        <span style={{ margin: '0 20px', marginRight: 0  }}>Terms</span>
                    </Link>
                    <Link to="/legal/content-policy">
                        <span style={{ margin: '0 20px' }}>Content Policy</span>
                    </Link>
                    <Link to="/legal/privacy">
                        <span>Privacy</span>
                    </Link>
                </div>

            </div>
            {/* <AndroidSignup
                shouldShow={shouldShowAndroidSignupModal}
                closeSelf={() => setShouldShowAndroidSignupModal(false)}
            /> */}
            <DownloadApp
                shouldShow={shouldShowDownloadAppModal}
                closeSelf={() => setShouldShowDownloadAppModal(false)}
                // onOpenAndroidModal={() => {
                //     setShouldShowDownloadAppModal(false)
                //     setShouldShowAndroidSignupModal(true)
                // }}
            />
        </div>
    )
}

export default Splash;
