import React from 'react';
import '../styles/OpenApp.css';
import {LiferollButton} from "./common/LiferollButton";

function OpenApp() {
    return (
        <div className="open-app">
            <div className="open-app-container">
                <h4>App didn't open?</h4>
                <LiferollButton label={"Try again"} style={{width:'200px', marginBottom:'15px'}} onPress={()=>window.open(`liferoll://${window.location.pathname}`)}/>
            </div>
        </div>
    )
}

export default OpenApp;
