import React, { useRef, useState } from 'react';

import '../../styles/AndroidSignup.css';
import { LiferollButton } from '../common/LiferollButton';
import { TextInput } from '../common/TextInput';
import joi from 'joi'
import { API_PUBLIC_URL, getResponseType, makePost } from '../common/utils';
import Checkmark from '../../assets/checkmark-flat.png'

function validateEmail (email: string) {
    try {
        joi.assert(email, joi.string().email({tlds: { allow: false }}))
        return true
    } catch(e) {
        return false
    }
}
const SIGNUP_ANDROID_URL = `${API_PUBLIC_URL}/android-signup/`
const API_ERROR_EMAIL_ALREADY_IN_USE = 'already signed up';

function AndroidSignup({shouldShow, closeSelf}: any) {
    // --------------------------- Declare states and refs  --------------------------- //
    const [email, setEmail] = useState('')
    const [emailInputError, setEmailInputError] = useState('')
    const emailInputRef = useRef<any>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccessful, setIsSuccessful] = useState(false)
    const [isEmailAlreadySignedUp, setIsEmailAlreadySignedUp] = useState(false)

    // --------------------------- Handle events  --------------------------- //
    const onEmailInputChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
        const text = event.target.value
        setEmail(text)
        if (text.trim().length) setEmailInputError('')
    }

    const onSubmit = async () => {
        if (!email.trim().length) return

        const isEmailValid = validateEmail(email)
        if (!isEmailValid) {
            setEmailInputError(`Oops. Please enter a valid email.`)
            emailInputRef.current?.select()
            return
        }

        setIsLoading(true)

        try {
            const response = await makePost(SIGNUP_ANDROID_URL, {
                email,
            })
            const responseType = getResponseType(response)
            if (responseType === '5XX') {
                alert('Oops. Something went wrong processing your request.\nIf issues persist please contact us.')
            } else if (responseType === '4XX') {
                const responseBody = await response.json()
                if (responseBody.message && responseBody.message.includes(API_ERROR_EMAIL_ALREADY_IN_USE)) {
                    setIsEmailAlreadySignedUp(true)
                } else {
                    // TODO -> SENTRY
                    alert('Oops. Looks like there was a problem signing you up.\nDouble check your email and try again. If issues persist, feel free to contact us.');
                }
            } else if (responseType === '2XX') {
                setIsSuccessful(true)
            } else {
                // TODO -> Sentry
                // Should never happen...
                alert('Oops. Something went wrong processing your request.\nIf issues persist please contact us.')
            }
        } catch(e) {
            // TODO in-app modal to handle this shit
            alert('Oops. Something went wrong processing your request.\nIf issues persist please contact us.')
        }
        setIsLoading(false)
    }

    // --------------------------- Render elements  --------------------------- //
    // ******* Default body - Sign up for android ******* //
    const signUpBody = (
        <div
            className={'android-signup-container'}
        >
            <h1>Liferoll for Android coming this year.</h1>
            <p>Want to be the first to find out when Android is ready?<br/>Join our waitlist below!</p>
            {/***** Email input *****/}
            <TextInput
                ref={emailInputRef}
                label={'Email'}
                placeholder={''}
                helperText={emailInputError}
                type={'email'}
                autoFocus={true}
                onChange={onEmailInputChange}
                onEnterPress={onSubmit}
                disabled={isLoading}
            />
            <p
                className={'email-input-subtitle'}
            >
                <b>Your Privacy is Our Policy: </b>
                We’ll only email you about the Android release.
            </p>
            {/***** Submit button *****/}
            <LiferollButton
                label="Get Notified"
                onPress={onSubmit}
                disabled={!!emailInputError || !email.trim().length || isLoading}
                style={{
                    marginTop: 12,
                }}
            />
            {/***** Close button *****/}
            <LiferollButton
                label="Close"
                onPress={closeSelf}
                disabled={isLoading}
                style={{
                    marginTop: 12,
                    backgroundColor: 'transparent',
                    background: 'none',
                    color: '#CCC'
                }}
            />
        </div>
    )

    // ******* On success ******* //
    const successBody = (
        <div>
            <img
                src={Checkmark}
                height={60}
                style={{
                    opacity: 0.9
                }}
            />
            <h1
                style={{
                    fontWeight: 'normal',
                }}
            >You're on the list!</h1>
            <p>We're so excited to see you soon.</p>

            {/***** Close button *****/}
            <LiferollButton
                label="Close"
                onPress={closeSelf}
                disabled={isLoading}
                style={{
                    marginTop: 12,
                    backgroundColor: 'transparent',
                    background: 'none',
                    color: '#CCC'
                }}
            />
        </div>
    )

    // ******* Email exists ******* //
    const emailAlreadySignedUpBody = (
        <div>
            <h1
                style={{
                    fontWeight: 'normal',
                }}
            >Looks like you're already on the list!</h1>
            <p>Hang tight. We'll be ready for Android soon.</p>

            {/***** Close button *****/}
            <LiferollButton
                label="Close"
                onPress={closeSelf}
                disabled={isLoading}
                style={{
                    marginTop: 12,
                    backgroundColor: 'transparent',
                    background: 'none',
                    color: '#CCC'
                }}
            />
        </div>
    )

    // ******* Main ******* //
    return (
        <div
            style={{display: shouldShow ? 'flex' : 'none'}}
            className={'modal-wrapper'}
            onClick={closeSelf}
        >
            {/***** Modal body *****/}
            <div
                className={'modal-container android-signup-modal'}
                // Prevent click within the body from closing the modal
                onClick={(e) => e.stopPropagation()}
            >
                {
                    isSuccessful ?
                    successBody :
                    isEmailAlreadySignedUp ?
                    emailAlreadySignedUpBody :
                    signUpBody
                }
            </div>
        </div>
    )
}

export default AndroidSignup;
