/** @format */

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Switch, Route, Link, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';

import 'fontsource-roboto';
import './react-stacked-carousel/dist/index.css';
import './App.css';

import Splash from './pages/Splash';
import Help from './pages/Help';
import loaderGif from './assets/loading.gif';
import Legal from './pages/Legal';
import SetPassword from './pages/Account/SetPassword';
import ResetPassword from './pages/Account/ResetPassword';
import VerifyEmail from './pages/Account/VerifyEmail';
import StoryViewer from './pages/StoryViewer/StoryViewer';
import NavBar from "./NavBar";
import OpenApp from "./pages/OpenApp";
import { DeleteSteps } from './pages/legal/DeleteSteps';

ReactGA.initialize('G-D32XZE0QEH');

function App() {

    return (
        <BrowserRouter>
            <AppSwitch/>
        </BrowserRouter>
    );
}


const AppSwitch = () => {
    const [childVideosLoaded, toggleChildVideosLoaded] = useState<boolean>(false);
    const [shouldShowContent, setShouldShowContent] = useState<boolean>(false);
    const [shouldShowNavbar, setShouldShowNavBar] = useState<boolean>(true)
    let location = useLocation()
    //if its not root, dont worry about the loading logic
    useEffect(() => {
        if (window.location.pathname !== '/') {
            toggleChildVideosLoaded(true);
            setShouldShowContent(true);
        }
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
        if(location.pathname.includes('/s/') || location.pathname.indexOf('/#') == 0 || location.pathname == '/'){
            setShouldShowNavBar(false)
        }else{
            setShouldShowNavBar(true)
        }
    }, [location]);

    return(
        <div style={{height:'100%'}}>
            <div
                className="loading"
                style={childVideosLoaded ? { opacity: 0, pointerEvents: 'none' } : { opacity: 1 }}
            >
                <img alt="Getting things ready..." src={loaderGif} />
            </div>
            <div className="App" style={shouldShowContent ? { opacity: 1 } : { opacity: 0 }}>
                {
                    shouldShowNavbar &&
                    <NavBar/>
                }
                <Switch>
                    <Route path="/open-app/*">
                        <OpenApp/>
                    </Route>
                    <Route path="/help">
                        <Help />
                        <div className="footer">
                            <Link to="/legal/terms">
                                <span>Terms</span>
                            </Link>
                            <Link to="/legal/content-policy">
                                <span style={{ margin: '0 20px' }}>Content Policy</span>
                            </Link>
                            <Link to="/legal/privacy">
                                <span>Privacy</span>
                            </Link>
                        </div>
                    </Route>
                    <Route path="/legal/content-policy">
                        <Legal tabItemProp="Content Policy" />
                    </Route>
                    <Route path="/legal/terms">
                        <Legal tabItemProp="Terms of Service" />
                    </Route>
                    <Route path="/legal/privacy">
                        <Legal tabItemProp="Privacy Policy" />
                    </Route>
                    <Route path="/admin/delete-account">
                        <DeleteSteps />
                    </Route>
                    <Route path="/set-password/:tokenId">
                        <SetPassword />
                    </Route>
                    <Route path="/reset-password/:tokenId">
                        <ResetPassword />
                    </Route>
                    <Route path="/verify-email/:tokenId">
                        <VerifyEmail />
                    </Route>
                    <Route path="/:ownerUsername/s/:publicLinkId">
                        <StoryViewer />
                    </Route>
                    <Route
                        path="/:username"
                        component={() => {
                            window.location.replace(`https://app.liferoll.co${window.location.pathname}`)
                            return null
                        }}
                    />
                    <Route path="/">
                        <Splash
                            toggleChildVideosLoaded={toggleChildVideosLoaded}
                            setShouldShowContent={setShouldShowContent}
                            shouldShowContent={shouldShowContent}
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}

export default App;
