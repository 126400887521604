import React, { useRef, useState } from 'react';
import qrCode from '../../assets/qrcode.png'
import iosDownloadSVG from '../../assets/download-ios.svg'
import androidDownloadPNG from '../../assets/download-android.png'

import '../../styles/DownloadApp.css';
import '../../styles/Splash.css';

function DownloadApp({shouldShow, closeSelf, onOpenAndroidModal}: any) {
    // --------------------------- Declare states and refs  --------------------------- //
    

    // --------------------------- Handle events  --------------------------- //

    // --------------------------- Render elements  --------------------------- //

    // ******* Main ******* //
    return (
        <div
            style={{display: shouldShow ? 'flex' : 'none'}}
            className={'modal-wrapper'}
            onClick={closeSelf}
        >
            {/***** Modal body *****/}
            <div
                className={'modal-container'}
                // Prevent click within the body from closing the modal
                onClick={(e) => e.stopPropagation()}
            >
                <div className="content-right-heading">
                    <div>Get Liferoll</div>
                    <span>Free on the App Store</span>
                </div>
                {/*-------- Mobile only --------*/}
                <div className="header-mobile-container">
                    <div className="header-mobile">Get Liferoll.
                        <div className='mobile-divider'/>
                    </div>
                    <span>Free on iPhone and Android</span>
                </div>
                {/*-----------------------------*/}
                <div className='qrcode-container'>
                    <img src={qrCode} className='qrcode' alt="QR Code"/>
                    <span>Use phone camera to scan</span>
                </div>
                <div className="download-buttons-container">
                    <a
                        href="https://apps.apple.com/us/app/liferoll/id1540436603"
                        style={{marginRight: '12px'}}
                    >
                        <img className='download-button' style={{padding: 0, border: "none"}}
                            src={iosDownloadSVG} alt="iOS Download"/>
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.liferoll.prana"
                    >
                        <img className='download-button' style={{padding: 0, border: "none"}}
                            src={androidDownloadPNG} alt="Android Download" height={40}/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default DownloadApp;
