import React from "react";
import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
        margin: 8,
    },
    inputLabel: {
        color: 'white',
        '&.Mui-focused': {
            color: 'white'
        },
        '&.Mui-disabled': {
            color: '#aaa',
        },
    },
    input: {
        color: 'white',
        '&.Mui-disabled': {
            color: '#999',
        },
        '&:after': {
            borderColor: 'white',
        },
        '&:before': {
            borderColor: '#444444',
        },
        '&:hover:not(.Mui-disabled):before': {
            borderColor: '#666666',
        },
    },
    helper: {
        color: '#E25519',
    },
  }),
);

type Props = {
    label: string
    placeholder: string
    helperText: string
    type: string
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    onEnterPress?: () => void
    autoFocus?: boolean
    disabled?: boolean
}

export const TextInput = React.forwardRef((props: Props, ref: any) => {
    const {
        label,
        placeholder,
        helperText,
        type,
        onChange,
        onEnterPress,
        autoFocus,
        disabled,
    } = props
    const classes = useStyles();

    return (
        <TextField
            inputRef={ref}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            type={type}
            autoFocus={!!autoFocus}
            onChange={onChange}
            onKeyPress={(e) => {
                if (e.key.toLowerCase() === 'enter') {
                    onEnterPress && onEnterPress()
                }
            }}
            id="standard-full-width"
            margin="normal"
            fullWidth
            disabled={disabled}
            className={classes.textField}
            InputLabelProps={{
                shrink: true,
                className: classes.inputLabel,
            }}
            InputProps={{
                className: classes.input,
            }}
            FormHelperTextProps={{
                className: classes.helper,
            }}
        />
    )
})