import React, { CSSProperties } from 'react'
import '../../styles/Button.css'


type Props = {
    label: string
    onPress: () => void
    disabled?: boolean
    style?: CSSProperties
}

export const LiferollButton = ({
    label,
    onPress,
    style,
    disabled,
}: Props) => {
    return (
        <button
            onClick={onPress}
            className={'liferoll-button'}
            disabled={!!disabled}
            style={style}
        >{label}</button>
    )
}