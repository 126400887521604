import React, { useState } from "react";
import "../styles/Legal.css";
import { PrivacyPolicy } from "./legal/PrivacyPolicy";
import { ContentPolicy } from "./legal/ContentPolicy";
import { TermsOfService } from "./legal/TermsOfService";
import Select from "react-select";
import PrivacyPolicySummary from "./legal/PrivacyPolicySummary";

const options = [
  { value: "Privacy Policy", label: "Privacy Policy" },
  { value: "Terms of Service", label: "Terms of Service" },
  { value: "Content Policy", label: "Content Policy" },
];

enum LEGAL_PAGES {
  PRIVACY_POLICY = "Privacy Policy",
  TOS = "Terms of Service",
  CONTENT_POLICY = "Content Policy",
}

// TODO: generalize with theme
const customStyles = {
  option: (provided: any, state: any) => ({
    height: "50px",
    display: "grid",
    placeItems: "center flex-start",
    paddingLeft: "15px",
    backgroundColor: state.isSelected ? "#2B2B2B" : "#1D1D1D",
    color: state.isSelected ? "white" : "#CCCCCC",
  }),
  menu: (provided: any, state: any) => ({
    backgroundColor: "#1D1D1D",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    marginRight: "8px",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    margin: 0,
    paddingLeft: "15px",
  }),
  control: () => ({
    backgroundColor: "#1D1D1D",
    color: "white",
    display: "flex",
    width: "100%",
    height: "50px",
  }),
  singleValue: (provided: any, state: any) => {
    const color = "white";
    return { ...provided, color };
  },
};

type Props = {
  tabItemProp: string;
};

function Legal(props: Props) {
  const [selectedTabItem, setTabItem] = useState<string>(props.tabItemProp);
  const handleTabSelect = (tab: any) => {
    if (tab.value) {
      tab = tab.value;
    }
    setTabItem(tab);
  };

  const _renderHeading = () => {
    if (selectedTabItem === LEGAL_PAGES.PRIVACY_POLICY) {
      return (
        <div>
          <PrivacyPolicySummary />
          <br />
          <p style={{ textAlign: 'center', color: '#999' }} >For more details, see our full policy below...</p>
          <br />
          <h1 className="legal-heading">{selectedTabItem}</h1>
        </div>
      );
    }
    return <h1 className="legal-heading">{selectedTabItem}</h1>;
  };

  return (
    <div className="legal">
      <div className="side-nav">
        <div className="legal-heading">Legal</div>
        <ul className="legal-options">
          <li
            onClick={() => handleTabSelect(LEGAL_PAGES.PRIVACY_POLICY)}
            className={
              selectedTabItem === LEGAL_PAGES.PRIVACY_POLICY ? "selected" : ""
            }
          >
            Privacy Policy
          </li>
          <li
            onClick={() => handleTabSelect(LEGAL_PAGES.TOS)}
            className={selectedTabItem === LEGAL_PAGES.TOS ? "selected" : ""}
          >
            Terms of Service
          </li>
          <li
            onClick={() => handleTabSelect(LEGAL_PAGES.CONTENT_POLICY)}
            className={
              selectedTabItem === LEGAL_PAGES.CONTENT_POLICY ? "selected" : ""
            }
          >
            Content Policy
          </li>
        </ul>
      </div>
      <Select
        options={options}
        onChange={(e) => e && handleTabSelect(e.value)}
        isSearchable={false}
        styles={customStyles}
        className="mobile-select"
        components={{
          IndicatorSeparator: () => null,
        }}
        value={{ value: selectedTabItem, label: selectedTabItem }}
      />
      <div className="legal-content">
        {_renderHeading()}
        {selectedTabItem === LEGAL_PAGES.PRIVACY_POLICY && <PrivacyPolicy />}
        {selectedTabItem === LEGAL_PAGES.TOS && <TermsOfService />}
        {selectedTabItem === LEGAL_PAGES.CONTENT_POLICY && <ContentPolicy />}
      </div>
    </div>
  );
}

export default Legal;
