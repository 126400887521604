import React, {useEffect, useRef, useState} from 'react';
import '../../styles/Account.css';
import { useParams } from 'react-router-dom';
import Spinner from '../../assets/spinner.gif'
import Checkmark from '../../assets/checkmark-flat.png'
import { API_PUBLIC_URL, getResponseType, isIOS, makePost } from '../common/utils';
import { LiferollButton } from '../common/LiferollButton';

const VERIFY_EMAIL_URL = `${API_PUBLIC_URL}/user/verify-email`

type Props = {

}
interface ParamTypes {
    tokenId: string
}
  
function VerifyEmail(props: Props) {
    // ---------------- Declare state, param and ref variables ---------------- //
    const { tokenId } = useParams<ParamTypes>()

    const [isSuccessful, setIsSuccessful] = useState(false)
    const [isFailed, setIsFailed] = useState(false)

    // ---------------- Define hooks and event handlers ---------------- //
    // Verify email right on load
    useEffect(() => {
        onSubmit()
    }, [])

    const onSubmit = async () => {
        try {
            const response = await makePost(VERIFY_EMAIL_URL, {
                tokenId,
            })
            const responseType = getResponseType(response)
            if (responseType == '5XX') {
                alert('Oops. Something went wrong processing your request.\nIf issues persist please contact us.')
            } else if (responseType == '4XX') {
                setIsFailed(true)
            } else if (responseType == '2XX') {
                setIsSuccessful(true)
            } else {
                // Should never happen...
                alert('Oops. Something went wrong processing your request.\nIf issues persist please contact us.')
            }
        } catch(e) {
            // TODO in-app modal to handle this shit
            alert('Oops. Something went wrong processing your request.\nIf issues persist please contact us.')
        }
    }

    // ---------------- Render elements ---------------- //

    {/***** Loading view while email is verified in bg *****/}
    const verifyEmailForm = (
        <div className="account-container">
            <img
                src={Spinner}
                height={60}
            />
            <h1
                style={{
                    fontWeight: 'normal',
                }}
            >Verifying your email...</h1>
        </div>
    )

    {/***** Email verification successful view *****/}
    const emailVerifiedConfirmation = (
        <div className="account-container">
            <img
                src={Checkmark}
                height={60}
                style={{
                    opacity: 0.9
                }}
            />
            <h1
                style={{
                    fontWeight: 'normal',
                }}
            >Your email's been verified!</h1>
        </div>
    )

    {/***** Link expired view *****/}
    const linkExpiredPrompt = (
        <div
            className="account-container"
            style={{
                paddingTop: '80px',
                paddingBottom: '80px',
            }}
        >
            <h1>
                Oops...
                {isIOS ? <br/> : ' '}
                This link may have expired.
            </h1>
            {isIOS ? (
                <div
                    style={{
                        display: 'block',
                        width: '90%',
                        marginBottom: '24px',
                        marginTop: '12px',
                    }}
                >
                    <hr
                        style={{
                            backgroundColor: '#2B2B2B',
                            height: '1px',
                            marginBottom: '32px',
                            width: '100%',
                            border: 'none',
                        }}
                    />
                    <p style={{ marginBottom: 16 }}>Request another one in your account settings.</p>
                    <LiferollButton
                        label={'Open App'}
                        // onPress={() => window.open('https://www.liferoll.co/open-app/account-settings/change-email')}
                        onPress={() => window.open('liferoll://open-app/account-settings/change-email')}
                    />
                </div>
            ) : <p>Use the app to get another one.</p>}
        </div>
    )

    {/***** Render based on state of flow *****/}
    return (
        <div
            className={'center-box-wrapper'}
        >
            {isSuccessful ? emailVerifiedConfirmation : isFailed ? linkExpiredPrompt : verifyEmailForm}
        </div>
    )
}

export default VerifyEmail;
