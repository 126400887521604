import moment from "moment";
import _ from 'lodash'
import {IClip, IStory, ProfileUrls} from "../StoryViewer/types";

export const API_BASE_URL = window.location.host === "www.liferoll.co"?
    `https://api.liferoll.co/api`:
    `https://staging-api.liferoll.co/api`
;
export const API_PUBLIC_URL = `${API_BASE_URL}/public`;
export const DEFERRED_DEEP_LINK_CLIPBOARD_PREFIX = 'lr-deferred-link:'
export const makePost = async (url: string, body: any): Promise<Response> => {
  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};

export const makeGet = async (url: string): Promise<Response> => {
  return await fetch(url);
};

export type ResponseCategories = "2XX" | "4XX" | "5XX" | "OTHER";

export const getResponseType = (res: Response): ResponseCategories => {
  const status = res.status;
  if (status >= 200 && status < 300) {
    return "2XX";
  } else if (status >= 400 && status < 500) {
    return "4XX";
  } else if (status >= 500 && status < 600) {
    return "5XX";
  }

  return "OTHER";
};

export const isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);


/**
 * Formats a date for display
 * ie. January 3 for same start and end day this yr
 *     Jan 3, 2020 for same start and end day last yr
 * Jan 3 - Feb 5, 2020 for different year from current year
 * Jan 3 - Feb 5 for same year as current year
 * Dec 5, 2020 - Feb 10, 2021 for varying years
 *
 * @param start
 * @param end
 * @returns {string}
 */
export function formatDateRangeForDisplay(start: Date, end: Date): string {
  // If the end is not the same day as the start, render the second half of the range
  const maybeSecondHalf = moment(start).isSame(moment(end), 'day')
      ? ''
      : ` – ${
          new Date().getFullYear() === new Date(end).getFullYear()
              ? moment(end).local().format('MMM D')
              : moment(end).local().format('MMM D, YYYY')
      }`

  const firstHalf =
      start.getFullYear() === end.getFullYear() && maybeSecondHalf
          ? moment(start).local().format(maybeSecondHalf ? 'MMM D' : 'MMMM D')
          : moment(start).local().format('MMM D, YYYY')

  return firstHalf + maybeSecondHalf
}

/**
 * If string greater than {@param maxLength}, trim it with a '...' at the end. If not, return the string
 * ie. Hello world -> Hello wo...
 * @param str
 * @param maxLength
 * @returns {string}
 */
export function trimString(str: string, maxLength: number): string {
  if (str.length < maxLength) return str
  return `${str.slice(0, maxLength - 3)}...`
}

/**
 * Converts a list of clips to a location summary text
 * ie. "Paris, France + 3"
 * @param clips
 */
export function getLocationSummary(clips: IClip[]): string {
  const clipsWithLocations = clips.filter(clip => clip.locationDisplayableTitle)
  if (!clipsWithLocations || !clipsWithLocations.length) return ''
  const locationsUnique = _.uniq(clipsWithLocations.map(clip => clip.locationDisplayableTitle))

  const firstClipLocation = trimString(locationsUnique[0]!, 30)
  const remainingLocationsCount =
      locationsUnique.length > 1 ? ` +${locationsUnique.length - 1}` : ''
  return firstClipLocation + remainingLocationsCount
}

/**
 * Generates signed url for user's cover image
 * @param {S3PresignCommonParams}
 * @returns {SignedProfileUrls}
 */
 export const getProfileMediaUrls = (userId: string): ProfileUrls => {
  const imageKeys = getProfilePhotoKeys(userId);
  const coverKeys = getProfileCoverPhotoKeys(userId);

  return {
      profilePhotoThumbnail: getUrl(imageKeys.profilePhotoThumbnail.key),
      profilePhotoMedium: getUrl(imageKeys.profilePhotoMedium.key),
      profileCoverPhotoSmall: getUrl(coverKeys.profileCoverPhotoSmall.key),
      profileCoverPhotoMedium: getUrl(coverKeys.profileCoverPhotoMedium.key),
  };
};

const getUrl = (objectKey: string) => {
  // TODO: put bucket name in env vars
  return `https://liferoll-prod.s3.amazonaws.com/${objectKey}`;
};

/**
 * mapping of profile image keys in s3
 *
 * @format
 * @param {S3DerriveKeysCommonParams}
 * @returns { profilePhotoThumbnail: string, profilePhotoMedium: string }
 */
 const getProfilePhotoKeys = (userId: string) => {
  const { photoFormat } = defaultFileFormatSet;
  return {
      profilePhotoThumbnail: {
          key: `user/${userId}/profile/image-thumbnail${photoFormat.extension}`,
          contentType: photoFormat.contentType as string,
      },
      profilePhotoMedium: {
          key: `user/${userId}/profile/image-medium${photoFormat.extension}`,
          contentType: photoFormat.contentType as string,
      },
  };
};

const defaultFileFormatSet = {
  photoFormat: {
      extension: '.jpg',
      contentType: 'image/jpeg',
  },
  videoFormat: {
      extension: '.mp4',
      contentType: 'video/mp4',
  },
  audioFormat: {
      extension: '.mp3',
      contentType: 'audio/mpeg',
  },
};
/**
* mapping of profile cover keys in s3
* @param {S3DerriveKeysCommonParams}
* @returns { profileCoverPhotoSmall: string, profileCoverPhotoMedium: string }
*/
const getProfileCoverPhotoKeys = (userId: string) => {
  const { photoFormat } = defaultFileFormatSet;
  return {
      profileCoverPhotoSmall: {
          key: `user/${userId}/profile/cover-small${photoFormat.extension}`,
          contentType: photoFormat.contentType as string,
      },
      profileCoverPhotoMedium: {
          key: `user/${userId}/profile/cover-medium${photoFormat.extension}`,
          contentType: photoFormat.contentType as string,
      },
  };
};

export function getCoverPhotoFallbackClipIdAndPostId(story: IStory): {
  clipId: string
  postId: string
} {
  if (story.latestPost) {
      if (story.latestPost?.clips?.length) {
          const clips = story.latestPost.clips.sort((clipA, clipB) => {
              return clipA.sequenceNumber - clipB.sequenceNumber
          })
          return {
              clipId: clips[0].clipId,
              postId: clips[0].postId,
          }
      }
  }

  if (_.size(story.posts)) {
      // return first clip
      const postWithClips = story.posts?.filter(post => post.clips?.length).pop()
      if (postWithClips) {
          const sortedClips = (postWithClips.clips || []).sort((clipA, clipB) => {
              return clipA.sequenceNumber - clipB.sequenceNumber
          })
          if (sortedClips.length) {
              return {
                  clipId: sortedClips[0].clipId,
                  postId: sortedClips[0].postId,
              }
          }
      }
  }
  return {
      clipId: '',
      postId: '',
  }
}
