/** @format */

import React, {useEffect, useRef, useState} from 'react';
import {
    API_PUBLIC_URL,
    DEFERRED_DEEP_LINK_CLIPBOARD_PREFIX,
    getCoverPhotoFallbackClipIdAndPostId,
    getLocationSummary,
    getProfileMediaUrls,
    getResponseType,
    isIOS,
    makeGet,
    trimString
} from '../common/utils';
import {IStory, IStoryResponse, SignedStoryUrls} from './types';
import emptyProfilePic from '../../assets/empty-profile-picture.png'
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import PlayIcon from "@material-ui/icons/PlayArrow";
// @ts-ignore
import ShowMoreText from 'react-show-more'
import {Helmet} from "react-helmet";
import '../../styles/StoryViewer.css';
import NavBar from "../../NavBar";
import logo from "../../assets/logo_400x400.png";
import qrCode from "../../assets/qrcode.png";
import iosDownloadSVG from "../../assets/download-ios.svg";
import {LiferollButton} from "../common/LiferollButton";
import AndroidSignup from "../modals/AndroidSignup";
import _ from 'lodash';

function StoryViewer() {
    const videoPlayerRef = useRef<HTMLVideoElement>(null);

    const WINDOW_HOSTNAME = window.location.pathname.split('/');
    const USERNAME = WINDOW_HOSTNAME[1];
    const STORY_LINK_ID = WINDOW_HOSTNAME[3];
    const GET_STORY_URL = `${API_PUBLIC_URL}/story/${USERNAME}/${STORY_LINK_ID}`;

    const [storyToView, setStoryToView] = useState<IStory>();
    const [storyUrls, setStoryUrls] = useState<SignedStoryUrls>();
    const [profilePicture, setProfilePicture] = useState<string>(emptyProfilePic)
    const [shouldShowModal, setShouldShowModal] = useState<boolean>(false)
    const [shouldShowAndroidSignupModal, setShouldShowAndroidSignupModal] = useState<boolean>(false)
    const [pageTitle, setPageTitle] = useState<string>('')
    const [pageCaption, setPageCaption] = useState<string>('')
    const [videoOverlayEnabled, setVideoOverlayEnabled] = useState<boolean>(true)
    const [showReplayControls, setShowReplayControls] = useState<boolean>(false)
    const [shouldShowCoverImage, setShouldShowCoverImage] = useState<boolean>(true)

    const maybeLocationSummary = getLocationSummary(storyToView?.latestPost?.clips || [])
    const music = trimString(storyToView?.music?.musicTitle || '', 25)

    const getStoryToView = async () => {
        try {
            const response = await makeGet(GET_STORY_URL);
            const responseType = getResponseType(response);
            if (responseType === '5XX' || responseType === '4XX') {
                alert(
                    'Oops. Something went wrong processing your request.\nIf issues persist please contact us.',
                );
            } else if (responseType === '2XX') {
                const responseData: IStoryResponse = await response.json()
                const storyToView = responseData.entity
                const signedUrls = responseData.signedUrls
                setStoryToView(storyToView);
                setStoryUrls(signedUrls);
                setPageTitle(`${storyToView.owner.fullName}'s Highlight`)
                setPageCaption(storyToView.storyCaption)

                const profileUrls = getProfileMediaUrls(storyToView.ownerId);
                setProfilePicture(profileUrls.profilePhotoThumbnail);
            } else {
                // Should never happen...
                alert(
                    'Oops. Something went wrong processing your request.\nIf issues persist please contact us.',
                );
            }
        } catch (e) {
            // TODO in-app modal to handle this shit
            alert(
                'Oops. Something went wrong processing your request.\nIf issues persist please contact us.',
            );
        }
    };

    const handleReplayPress = () => {
        if (videoPlayerRef.current) {
            videoPlayerRef.current.currentTime = 0
            videoPlayerRef.current.play()
            setShowReplayControls(false)
        }
    }

    const handleOverlayPress = () => {
        if (!showReplayControls) {
            videoOverlayEnabled ? videoPlayerRef.current?.play() : videoPlayerRef.current?.pause()
        }
        setShouldShowCoverImage(false)
    }

    const renderPlaybackOptions = () => {
        if (showReplayControls) {
            return (
                <div className="replayControls">
                    {
                        !isIOS &&
                        <div style={{border: "none", textAlign: 'center', width: '300px'}}>
                            <p style={{marginBottom: '8px', fontWeight: 'bold'}}>Liferoll for Android coming soon</p>
                            <p style={{fontWeight: 'normal', marginBottom: '12px'}}>
                                We just launched a Beta for iOS, and we’re working hard to get the experience right for
                                you.
                                We can’t wait to welcome you soon!
                            </p>
                        </div>
                    }
                    <div
                        className="ctaButton"
                        onClick={() => {
                            if (!isIOS) {
                                setShouldShowAndroidSignupModal(true)
                            } else {
                                copyDeferredDeepLink()
                                window.location.href = "https://apps.apple.com/us/app/liferoll/id1540436603"
                            }
                        }}>
                        <p>{isIOS ? 'View Full Highlight in App' : 'Join Android Waitlist'}</p>
                    </div>
                    <div className="ctaButton" style={{border: "none"}} onClick={handleReplayPress}>
                        <p>Restart Preview</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="replayControls">
                    <PlayIcon style={{color: "white", width: 80, height: 80}}/>
                </div>
            )
        }
    }

    const renderStoryInfo = (isMobile: boolean) => {
        return (
            <div className={isMobile ? "storyInfoMobile" : "storyInfo"}>
                <div className="nameAndLink">
                    <div className="userFullName">
                        <div className="profilePictureContainer">
                            {
                                storyUrls &&
                                <img
                                    onError={() => {
                                        setProfilePicture(emptyProfilePic)
                                    }}
                                    src={profilePicture}
                                    alt={'Profile picture'}
                                />
                            }
                        </div>
                        {storyToView?.owner?.fullName}
                    </div>
                </div>
                <div className="captionContainer">
                    <ShowMoreText
                        lines={2}
                        more='Show more'
                        less='Show less'
                        className='captionContainer'
                        anchorClass='captionAnchor'
                        expanded={false}
                    >
                        <p className="caption">
                            {storyToView?.storyCaption}
                        </p>
                    </ShowMoreText>
                </div>
                <div className="titleDateTags">
                    <div className="dateAndTags">
                        {
                            maybeLocationSummary ?
                                (
                                    <div className={"tagsContainer"}>
                                        <div className="storyLocationPills">{maybeLocationSummary}</div>
                                    </div>
                                ) : null
                        }
                        {
                            maybeLocationSummary
                            && music && (
                                <span className="bullet">&bull;</span>
                            )
                        }
                        {
                            music && (
                                <div className={"tagsContainer"}>
                                    <span className="music">
                                                    <MusicNoteIcon
                                                        style={{color: "#CCCCCC", width: 10, marginRight: 4}}/>
                                        {music}
                                        </span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

    const copyDeferredDeepLink = () => {
        let link = DEFERRED_DEEP_LINK_CLIPBOARD_PREFIX + '/' + storyToView?.owner?.username + '/s/' + storyToView?.publicLinkId
        navigator.clipboard.writeText(link);
    };

    useEffect(() => {
        (async () => {
            await getStoryToView();
        })()
    }, []);

    const coverClipIdenfiters = storyToView ? getCoverPhotoFallbackClipIdAndPostId(storyToView) : undefined
    const coverPhotoURI = coverClipIdenfiters ? (storyUrls?.clips && storyUrls?.clips[coverClipIdenfiters.clipId]) || (storyUrls?.posts && _.get(storyUrls?.posts[coverClipIdenfiters.postId], coverClipIdenfiters.clipId)) : ''

    return (
        <div className="story-viewer-container">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{pageTitle}</title>
                <meta name="description" content={pageCaption}/>
            </Helmet>
            <div className="navbarWrapper">
                <NavBar/>
            </div>
            <div className="belowBanner">
                <div className="storyContent">
                    <div className="storyPlayer">
                        <div className={'mobileOverlay'} style={{opacity: videoOverlayEnabled ? 1 : 0}}
                             onClick={handleOverlayPress}>
                            <div className="mobileBanner">
                                <img src={logo} className="logo" alt="logo"/>
                                <div className="mobileBannerText">
                                    <p>
                                        Get the full experience
                                    </p>
                                    <span>
                                        {isIOS ? 'Find Liferoll free on the App Store' : 'Join the Android waitlist today'}
                                    </span>
                                </div>
                                <a onClick={() => {
                                    if (!isIOS) {
                                        setShouldShowAndroidSignupModal(true)
                                    } else {
                                        copyDeferredDeepLink()
                                        window.location.href = "https://apps.apple.com/us/app/liferoll/id1540436603"
                                    }
                                }} className="mobileBannerButton">
                                    <p>{isIOS ? 'VIEW' : 'JOIN'}</p>
                                </a>
                            </div>
                            <div className="darkenOverlay"/>
                            <img className="overlayPhoto" src={coverPhotoURI}
                                 style={{opacity: videoOverlayEnabled && shouldShowCoverImage ? 1 : 0}}/>
                            {renderPlaybackOptions()}
                            {renderStoryInfo(true)}
                        </div>
                        {/* TODO: @Jake would commenting this out break shit? */}
                        {/* <video
                            ref={videoPlayerRef}
                            autoPlay={true}
                            playsInline
                            muted={false}
                            controls={window.innerWidth > 1000}
                            className="storyViewerVideo"
                            src={storyUrls?.storyCoverVideo}
                            onPlay={() => {
                                setVideoOverlayEnabled(false)
                                setShowReplayControls(false)
                            }}
                            onPause={() => setVideoOverlayEnabled(true)}
                            onEnded={() => setShowReplayControls(true)}
                        /> */}
                    </div>
                    {
                        renderStoryInfo(false)
                    }
                </div>
                <div className="downloadContent">
                    <div>
                        <div className="content-right-heading" style={{textAlign: 'center'}}>
                            <div>Get the Liferoll app to view</div>
                            <div>{storyToView?.owner?.fullName}'s full highlight</div>
                        </div>
                        <div className='qrcode-container'>
                            <img src={qrCode} className='qrcode' alt="QR Code"/>
                            <span>Use phone camera to scan</span>
                        </div>
                        <div className="download-buttons-container"
                             style={{alignItems: 'center', justifyContent: "center"}}>
                            <a
                                href="https://apps.apple.com/us/app/liferoll/id1540436603"
                                style={{marginRight: '12px'}}
                            >
                                <img className='download-button' style={{padding: 0, border: "none"}}
                                     src={iosDownloadSVG} alt="iOS Download"/>
                            </a>
                            <a
                                href="#"
                                onClick={() => {
                                    setShouldShowAndroidSignupModal(true)
                                    setShouldShowModal(false)
                                }}
                            >
                                <div className="download-button">
                                    Have Android?
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {
                shouldShowModal &&
                <div className="modalContainer">
                    <div className="downloadModal">
                        <div className="content-right-heading">
                            <div>Get Liferoll</div>
                            <span>Free on the App Store</span>
                        </div>
                        {/*-------- Mobile only --------*/}
                        <div className="header-mobile-container">
                            <div className="header-mobile">Your life's experiences<br/>in vivid stories.
                                <div className='mobile-divider'/>
                            </div>
                            <span>Get Liferoll</span>
                        </div>
                        {/*-----------------------------*/}
                        <div className='qrcode-container'>
                            <img src={qrCode} className='qrcode' alt="QR Code"/>
                            <span>Use phone camera to scan</span>
                        </div>
                        <div className="download-buttons-container">
                            <a
                                href="https://apps.apple.com/us/app/liferoll/id1540436603"
                                style={{marginRight: '12px'}}
                            >
                                <img className='download-button' style={{padding: 0, border: "none"}}
                                     src={iosDownloadSVG} alt="iOS Download"/>
                            </a>
                            <a
                                href="#"
                                onClick={() => {
                                    setShouldShowAndroidSignupModal(true)
                                    setShouldShowModal(false)
                                }}
                            >
                                <div className="download-button">
                                    Have Android?
                                </div>
                            </a>
                        </div>
                        <LiferollButton
                            label={'Close'}
                            onPress={() => {
                                setShouldShowModal(false)
                            }}
                            style={{backgroundColor: '#2B2B2B', backgroundImage: "none", marginTop: 24}}
                        />
                    </div>
                </div>
            }
            <AndroidSignup
                shouldShow={shouldShowAndroidSignupModal}
                closeSelf={() => setShouldShowAndroidSignupModal(false)}
            />

        </div>
    );
}


export default StoryViewer;
